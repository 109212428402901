import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme) => {
  return {
    content: {
      padding: `${theme.spacing(4)} ${theme.spacing(5)}  ${theme.spacing(4)} ${theme.spacing(5)}`,
      [theme.breakpoints.down('md')]: {
        padding: `${theme.spacing(0)} ${theme.spacing(3)}  ${theme.spacing(4)} ${theme.spacing(3)}`,
        
        marginTop: theme.spacing(4),
      },
      minHeight: "50vh"
    },
    widgetContent: {
      // backgroundColor: "red",
      padding: `${theme.spacing(4)} ${theme.spacing(5)}  ${theme.spacing(4)} ${theme.spacing(5)}`,
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(3),
        marginTop: theme.spacing(4),
      }
    },
    toggleBtn: {
      '& div.MuiToggleButtonGroup-root': {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
        '& button': {
          gap: theme.spacing(1),
          border: `1px solid ${theme.palette.secondary.main}`,
          fontSize: '14px',
          fontWeight: '500',
          color: theme.palette.info.dark,
          textTransform: 'capitalize',
          height: '40px',
        },
        '& button.Mui-selected': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
        },
        '& button:first-child': {
          borderTopLeftRadius: theme.spacing(3),
          borderBottomLeftRadius: theme.spacing(3),
        },
        '& button:last-child': {
          borderTopRightRadius: theme.spacing(3),
          borderBottomRightRadius: theme.spacing(3),
        }
      },
    }
  }
},
);


export default styles;