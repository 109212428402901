import * as React from "react";
import Button from "@mui/material/Button";
import { styled, useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";
import styles from "./styles";
import { useNavigate } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function HelpdeskDialog({ open, setOpen }) {
  const { classes } = styles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const handleInternalButtonClick = () => {
    const currentPath = window.location.pathname;
    const targetPath = "/workflow";

    if (currentPath === targetPath) {
      window.location.reload();
    } else {
      navigate(targetPath);
    }
    handleClose();
  };

  const handleExternalButtonClick = () => {
    window.open(
      "https://support.jira.stewardpartners.com/servicedesk/customer/portals",
      "_blank"
    );
    handleClose();
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog"
        open={open}
        fullScreen={fullScreen}
        className={classes.dialog}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          className={classes.dialogBoxTitle}
        >
          Steward Partners Service Center
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.info.dark,
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers style={{ padding: "0 16px" }}>
          <div className={classes.dialogBody}>
            <div className={classes.dialogBodySection1}>
              <Typography gutterBottom className={classes.dialogBoxDescription}>
                Raise requests for trade corrections, annuities, alternative investments, finance, compliance, and other key business processes—all within a seamless, streamlined workflow.
              </Typography>
              <div style={{ marginTop: 'auto' }}>
                <Button
                  className={classes.dialogBoxActivityButton}
                  onClick={handleInternalButtonClick}
                >
                  SteWork
                </Button>
              </div>
            </div>

            <div className={classes.dialogBodySection2}>
              <Typography gutterBottom className={classes.dialogBoxDescription}>
                Submit Technology Service requests such as access to systems, device requests, or reporting issues and incidents.
              </Typography>
              <div style={{ marginTop: 'auto' }}>
                <Button
                  className={classes.dialogBoxActivityButton}
                  onClick={handleExternalButtonClick}
                >
                  Technology Service Desk
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}