import React, { useEffect, useState } from 'react';
import styles from "./styles";
import { useTheme } from "@mui/material/styles";
import { Box, Typography } from '@mui/material';
import Breadcrumb from '../../components/Breadcrumb/index'
import { Link } from "react-router-dom";
import Footer from '../../components/Footer';
import Header from "../../components/Header/Index";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDispatch, useSelector } from 'react-redux';
import { setTrainingDetail } from '../../redux/reducers/TrainingDetailPath/trainingDetailPathSlice';
import { getTrainingListData } from "../../redux/actions/TrainingList/trainingList";
import { fetchAndStoreToken } from "../../redux/actions/oAuthToken/oAuthToken";
import Loader from '../../components/Loader';

export default function TrainingList() {
    const theme = useTheme();
    const { classes } = styles();
    const dispatch = useDispatch();
    const trainingListAPI = window.location.pathname;
    const [trainingListData, setTrainingListData] = useState(null);
    const { trainingList, status, loading, error } = useSelector((state) => state.trainingList);
    const subjectID = trainingListData?.subject_id;
    const subjectTitle = trainingListData?.subject_title;
    const subjectSummary = trainingListData?.subject_summary;

    const oAuthToken = localStorage.getItem("oAuthToken");
    const [isTokenReady, setIsTokenReady] = useState(false);
    const { tokenFetched } = useSelector((state) => state.oAuthToken);

    useEffect(() => {
        // If the token is not available in localStorage, fetch and store it
        if (!oAuthToken) {
            dispatch(fetchAndStoreToken()); // Action to fetch and store the token in localStorage
        } else {
            setIsTokenReady(true); // Token is already in localStorage
        }
    }, [oAuthToken, dispatch]); // Dependency array includes `oAuthToken` so that it triggers when the token is updated in localStorage

    useEffect(() => {
        const tokenFromLocalStorage = localStorage.getItem("oAuthToken");
        if (tokenFromLocalStorage) {
            setIsTokenReady(true); // Token is ready, trigger API call
        } else {
            setIsTokenReady(false); // Token is still not available
        }
    }, [tokenFetched]);

    // Trigger the detail API call when the token is ready and API path is available
    useEffect(() => {
        if (isTokenReady && trainingListAPI) {
            dispatch(
                getTrainingListData({
                    trainingListAPI: trainingListAPI,
                    oAuthToken: oAuthToken, // Using the token from localStorage
                })
            );
        }
    }, [isTokenReady, trainingListAPI, dispatch]);

    useEffect(() => {
        if (trainingList && trainingList.length > 0) {
            setTrainingListData(trainingList[0]);
        }
    }, [trainingList]);

    const handleTrainingDetail = (listItem, subjectTitle) => {
        dispatch(
            setTrainingDetail({
                trainingDetailItem: { ...listItem, subjectTitle }
            })
        );
    };

    return (
        <>
            <Header trainualPage={true} position={"relative"} top="0px" />
            {loading ? <Loader /> : ""}
            {!loading && <Box className={classes.trainualListContainer}>
                <div className={classes.trainualListHeaderBox}>
                    <Breadcrumb subjectTitle={subjectTitle} />
                    <div className={classes.trainualListHeaderText}>{subjectTitle ? subjectTitle.replace(/&amp;/gi, '&') : ''}</div>
                    <p className={classes.trainualListDescription}>{subjectSummary ? subjectSummary.replace(/&amp;/gi, '&') : ''}</p>
                </div>
                <Box className={classes.trainualListItemBox}>
                    {trainingListData?.topic_data.map((listItem, index) => (
                        <Box key={index} className={classes.trainualListItem}>
                            <Link style={{ textDecoration: 'none' }} to={`/TrainingDetail/subject/${subjectID}/${listItem.topic_id}`} onClick={() => handleTrainingDetail(listItem, subjectTitle)}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: 'space-between',
                                        cursor: "pointer",
                                    }}
                                >
                                    <Typography>{listItem.topic_title.replace(/&amp;/gi, '&')}</Typography>
                                    <ArrowForwardIcon sx={{ color: theme.palette.common.grey }} />
                                </div>
                            </Link>
                        </Box>
                    ))}
                </Box>
            </Box>}
            <Footer />
        </>
    );
};