import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../../config";
import { getIdToken } from "../../../helper/auth";

const hostname = window.location.hostname;

const port = window.location.port;

const portalApiBaseUrl = port ? 'http://'+hostname + ":" + port : 'https://'+hostname;

export const getAutoComplete = createAsyncThunk(
  "/api/autocomplete",
  async ({searchQuery, rolesArray}, { rejectWithValue }) => {
    try {
      const IdToken = await getIdToken();
      const headers = {
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${IdToken}`,
          },
      };
    // Regex to handle empty search queries
    const searchQueryRegex = /(^''$|^""$|^'$|^"$)/g;
    const matches = searchQuery && searchQuery.match(searchQueryRegex);

      let checkRoles = [
        "SG_SSO-Employee_Role",
        "SG_SSO-Affiliate_Role",
        "SG_SSO-Contractor_Role",   
      ];
  
      let currentUserRole = checkRoles.find((value) =>
        rolesArray?.includes(value)
      );
      
    // Construct the search query body
    const bodyData = {
      search: matches ? "*" : searchQuery,
      select:"Title, Content_Type, Url, ",
      filter:  `(Audience eq null or Audience eq '' or Audience eq 'Everyone' or Audience eq '${
            currentUserRole !== undefined ? currentUserRole : ""
          }')`,
      queryType: "simple",
      searchMode: "any",
      scoringProfile:"default",
      top:20
    };

      // Call your Node API to proxy the request to Azure Cognitive Search
      const response = await axios.post(`${portalApiBaseUrl}/api/search`, { searchQuery, bodyData}, headers);

      // if (response.status === 200) {
      //       sessionStorage.setItem("refreshDialog", "false");
      //     }
      // If the request is successful, return the data
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      //throw error;
      // return custom error message from backend if present
      // if (error.response.status) {
      //   sessionStorage.setItem("refreshDialog", "true");
      // }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
