import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "./../../../config";

export const postFeedback = createAsyncThunk(
  "webform_rest/submit?_format=json",
  async ({feedbackPayload,oAuthToken}, { rejectWithValue }) => {
    try {
      const headers = {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Basic ${basicAuthString}`, //`Bearer ${oAuthToken}` if access token authorization
          Authorization: `Bearer ${oAuthToken}`, //`Bearer ${oAuthToken}` if access token authorization
        },
      };
      const response = await axios.post(
        `${config.drupalapiBaseUrl}/webform_rest/submit?_format=json`,
        feedbackPayload,
        headers
      );
      
      if (response.status === 200) {
        sessionStorage.setItem("refreshDialog", "false");
      }

      return response.data;
    } catch (error) {
      //throw error;
      // return custom error message from backend if present
      if (error.response.status === 401) {
        sessionStorage.setItem("refreshDialog", "true");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
