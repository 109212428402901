import React, { useEffect, useState } from 'react';
import styles from "./styles";
import { Box, Button, Tab, Tabs, useMediaQuery } from '@mui/material';
import Breadcrumb from '../../components/Breadcrumb/index'
import Header from '../../components/Header/Index';
import Footer from '../../components/Footer';
import TrainingDetailPage from './TrainingDetailPage';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { getTrainingDetailData } from "../../redux/actions/TrainingDetail/trainingDetail";
import { fetchAndStoreToken } from '../../redux/actions/oAuthToken/oAuthToken';
import Loader from '../../components/Loader';

export default function TrainingDetail() {
    const { classes } = styles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [value, setValue] = useState(0);
    const isMobile = useMediaQuery("(max-width:768px)");
    const trainingDetailAPI = window.location.pathname;
    const [trainingDetailData, setTrainingDetailData] = useState(null);
    const { trainingDetail, status, loading, error } = useSelector((state) => state.trainingDetail);

    const oAuthToken = localStorage.getItem("oAuthToken");
    const [isTokenReady, setIsTokenReady] = useState(false);
    const { tokenFetched } = useSelector((state) => state.oAuthToken);

    useEffect(() => {
        // If the token is not available in localStorage, fetch and store it
        if (!oAuthToken) {
            dispatch(fetchAndStoreToken()); // Action to fetch and store the token in localStorage
        } else {
            setIsTokenReady(true); // Token is already in localStorage
        }
    }, [oAuthToken, dispatch]); // Dependency array includes `oAuthToken` so that it triggers when the token is updated in localStorage

    useEffect(() => {
        const tokenFromLocalStorage = localStorage.getItem("oAuthToken");
        if (tokenFromLocalStorage) {
            setIsTokenReady(true); // Token is ready, trigger API call
        } else {
            setIsTokenReady(false); // Token is still not available
        }
    }, [tokenFetched]);

    // Trigger the detail API call when the token is ready and API path is available
    useEffect(() => {
        if (isTokenReady && trainingDetailAPI) {
            dispatch(
                getTrainingDetailData({
                    trainingDetailAPI: trainingDetailAPI,
                    oAuthToken: oAuthToken, // Using the token from localStorage
                })
            );
        }
    }, [isTokenReady, trainingDetailAPI, dispatch]);

    useEffect(() => {
        if (trainingDetail && trainingDetail.length > 0) {
            setTrainingDetailData(trainingDetail[0]);
        }
    }, [trainingDetail]);

    const topicTitle = trainingDetailData?.topic_title;
    const subjectTitle = trainingDetailData?.title;
    const topic_contents_id = trainingDetailData?.topic_contents_id
        ? trainingDetailData.topic_contents_id.toString().split(",")
        : null;
    const topic_contents = trainingDetailData?.topic_data;
    const sortedTopicContents = topic_contents_id && Array.isArray(topic_contents_id)
        ? topic_contents_id.flatMap((id) =>
            topic_contents && Array.isArray(topic_contents)
                ? topic_contents.find((content) => content.content_id.toString() === id.trim()) || []
                : []
        )
        : [];

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handlePreviousTab = () => {
        if (value > 0) setValue((prev) => prev - 1);
    };

    const handleNextTab = () => {
        if (trainingDetailData && value < sortedTopicContents.length - 1) {
            setValue((prev) => prev + 1);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [value]);

    return (
        <>
            <Header trainualPage={true} position={"relative"} top="0px" />
            {loading ? <Loader /> : ""}
            {!loading && <Box className={classes.trainualContentContainer}>
                <div className={classes.breadcrumbBox}><Breadcrumb subjectTitle={subjectTitle} topicTitle={topicTitle} /></div>
                {sortedTopicContents && Array.isArray(sortedTopicContents) && sortedTopicContents.length > 0 ? (<Box className={isMobile ? classes.mobileTrainualContentTabsBox : classes.trainualContentTabsBox}>
                    <Tabs
                        orientation={isMobile ? "horizontal" : "vertical"}
                        variant="scrollable"
                        value={value}
                        onChange={handleTabChange}
                        className={isMobile ? classes.mobileLeftNavigationPanelBox : classes.leftNavigationPanelBox}
                    >
                        {sortedTopicContents.map((tab, index) => (
                            <Tab
                                key={index}
                                label={tab?.content_title ? tab.content_title.replace(/&amp;/gi, '&') : ""}
                                icon={<FormatListNumberedOutlinedIcon />}
                                iconPosition="start"
                                sx={{ textAlign: 'left', justifyContent: 'flex-start' }}
                            />
                        ))}
                    </Tabs>
                    <Box className={classes.rightTrainualContentBox}>
                        {sortedTopicContents[value] ? (
                            <>
                                <TrainingDetailPage content={sortedTopicContents[value].content_description} attachment={sortedTopicContents[value].attachment} />
                                <Box className={classes.navigationButtonsBox}
                                    sx={{
                                        justifyContent: value === 0 ? 'flex-end'
                                            : value === sortedTopicContents.length - 1 ? 'flex-start' : 'space-between'
                                    }}
                                >
                                    {value > 0 && < Button
                                        variant="contained"
                                        onClick={handlePreviousTab}
                                        sx={{ minWidth: isMobile ? '130px' : '150px' }}
                                    >
                                        Previous Step
                                    </Button>}
                                    {value < sortedTopicContents.length - 1 && <Button
                                        variant="contained"
                                        onClick={handleNextTab}
                                        sx={{ minWidth: isMobile ? '130px' : '150px' }}
                                    >
                                        Next Step
                                    </Button>}
                                </Box>
                            </>
                        ) : (
                            <h3 style={{ color: theme.palette.secondary.main }}> Content not available</h3>
                        )}
                    </Box>
                </Box>) : (
                    <Box className={classes.trainualContentNotAvailableBox}>
                        <h3 style={{ color: theme.palette.secondary.main }}></h3>
                    </Box>
                )}
            </Box >}
            <Footer id="footer-detail" />
        </>
    );
};
