import React, { useEffect, useState } from 'react';
import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import "./style.scss";
import { Menu, Grid, MenuItem, Box, IconButton, useMediaQuery, Checkbox, ListItemText } from '@mui/material';
import styles from '../Widgets/styles';
import { useTheme } from '@mui/material/styles';
import WidgetsIcon from '@mui/icons-material/Widgets';
import ImportantUpdates from '../ImportantUpdates';
import CompanyNews from '../CompanyNews';
import { setSelectedWidgetsDB, setLayoutDB } from '../../redux/reducers/Widgets/widgetSlice';
import { fetchWidgetsData, saveWidgetsData } from '../../redux/actions/Widgets/widgets';
import { useDispatch, useSelector } from 'react-redux';
import SimpleSlider from '../../components/carousel';
import Loader from '../../components/Loader';
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { Link } from "react-router-dom";

const Widgets = (props) => {
    const { importantUpdates, companyNews } = props;
    const { classes } = styles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery("(max-width:768px)");
    const { selectedWidgetsDB, layoutDB, fetched } = useSelector((state) => state.widgets);
    const userId = useSelector((state) => state.userData?.userData?.id);
    const [layout, setLayout] = useState(layoutDB);
    const [selectedWidgets, setSelectedWidgets] = useState(selectedWidgetsDB);
    const [anchorEl, setAnchorEl] = useState(null);
    const [gridWidth, setGridWidth] = useState(document.documentElement.clientWidth - 66);
    const open = Boolean(anchorEl);
    const openWidgetDropdown = (event) => setAnchorEl(event.currentTarget);
    const closeWidgetDropdown = () => setAnchorEl(null);
    const defaultWidgets = ['Favorites', 'ImportantUpdates', 'CompanyNews'];

    // Define initial layouts and widget configurations
    const initialLayout = [
        { i: 'Favorites', x: 0, y: 0, w: 12, h: 3, minW: 6, minH: 2, static: false, isResizable: true },
        { i: 'CompanyNews', x: 0, y: 3, w: 6, h: 4, minW: 4, minH: 2, static: false, isResizable: true },
        { i: 'ImportantUpdates', x: 6, y: 3, w: 6, h: 4, minW: 4, minH: 2, static: false, isResizable: true }
    ];

    // const widgetNames = initialLayout.map((widget) => ({
    //     id: widget.i,
    //     name: widget.i
    // }));

    useEffect(() => {
        const handleResize = () => setGridWidth(document.documentElement.clientWidth - 66);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Fetch initial data on mount
    useEffect(() => {
        if (userId) {
            dispatch(fetchWidgetsData(userId)).then((response) => {
                const userWidgets = response?.payload?.[0]?.selectedWidgets || [];
                const userLayout = response?.payload?.[0]?.layout || [];

                // If no widgets are found in the database, use default widgets
                const updatedWidgets = userWidgets && userWidgets.length > 0
                    ? [...new Set([...defaultWidgets, ...userWidgets])]
                    : defaultWidgets;

                setSelectedWidgets(updatedWidgets);
                dispatch(setSelectedWidgetsDB(updatedWidgets));

                // Initialize layout with selected widgets
                const updatedLayout = updatedWidgets.map((widgetId) =>
                    layoutDB?.find((widget) => widget.i === widgetId) ||
                    userLayout?.find((widget) => widget.i === widgetId) ||
                    initialLayout?.find((widget) => widget.i === widgetId)
                );

                setLayout(updatedLayout);
                dispatch(setLayoutDB(updatedLayout));

                // Only save widgets if they are new (avoiding duplicate API calls)
                // if (!userWidgets || userWidgets.length === 0) {
                //     // dispatch(saveWidgetsData({ userId, selectedWidgets: updatedWidgets, layout: updatedLayout }));
                // }
            });
        }
    }, [dispatch, userId]);

    // const rebuildLayout = () => {
    //     const activeLayout = selectedWidgetsDB.map((widgetId) =>
    //         layoutDB.find((widget) => widget.i === widgetId) || initialLayout.find((widget) => widget.i === widgetId)
    //         // initialLayout.find((widget) => widget.i === widgetId)
    //     );
    //     setLayout(activeLayout);
    //     dispatch(setLayoutDB(activeLayout));
    // };

    // const handleToggle = (widgetId) => {
    //     const updatedWidgets = selectedWidgetsDB.includes(widgetId)
    //         ? selectedWidgetsDB.filter((id) => id !== widgetId)
    //         : [...selectedWidgetsDB, widgetId];

    //     setSelectedWidgets(updatedWidgets);
    //     dispatch(setSelectedWidgetsDB(updatedWidgets));

    //     // Rebuilds the layout based on selectedWidgets
    //     rebuildLayout();

    //     // Restore full layout from layoutDB for widgets that are re-enabled
    //     // Initiallayout is used as a callback if layoutDB is missing
    //     // const updatedLayout = initialLayout.filter((widget) =>
    //     //     updatedWidgets.includes(widget.i)
    //     // );

    //     const updatedLayout = updatedWidgets.map((widgetId) =>
    //         layoutDB.find((widget) => widget.i === widgetId) ||
    //         initialLayout.find((widget) => widget.i === widgetId)
    //     );

    //     setLayout(updatedLayout);
    //     dispatch(setLayoutDB(updatedLayout));

    //     if (userId) {
    //         dispatch(saveWidgetsData({ userId, selectedWidgets: updatedWidgets, layout: updatedLayout }));
    //     }
    // };

    const onLayoutChange = (newLayout) => {
        setLayout(newLayout);
        dispatch(setLayoutDB(newLayout));
        if (userId) {
            dispatch(saveWidgetsData({ userId, selectedWidgets: selectedWidgetsDB, layout: newLayout }));
        }
    };

    const getComponent = (component) => {
        switch (component) {
            case 'Favorites':
                return <SimpleSlider />;
            case 'CompanyNews':
                return <CompanyNews importantUpdates={importantUpdates} companyNews={companyNews} />;
            case 'ImportantUpdates':
                return <ImportantUpdates importantUpdates={importantUpdates} companyNews={companyNews} />;
            default:
                return <div>Content not available</div>;
        }
    };

    const getComponentHeading = (component) => {
        switch (component) {
            case 'Favorites':
                return (
                    <Grid className={classes.favContentItem1} item sm={12} md={3}>
                        <div
                            style={{
                                display: "flex",
                                fontFamily: "Oswald-Regular",
                                fontSize: "32px",
                                fontWeight: "600",
                                color: theme.palette.secondary.main,
                                paddingLeft: isMobile ? "0px" : "16px",
                            }}
                        >
                            <FavoriteBorderOutlinedIcon
                                sx={{
                                    fontSize: "1.5rem",
                                    borderRadius: "40px",
                                    padding: "8px",
                                    marginRight: isMobile ? "8px" : "16px",
                                    backgroundColor: theme.palette.favorite.light,
                                    color: theme.palette.primary.main,
                                    width: "32px",
                                    height: "32px"
                                }}
                            />
                            Favorites
                        </div>
                        <Link
                            to={"/Favorites"}
                            style={{
                                position: "absolute",
                                right: "0px",
                                textDecoration: "none",
                                zIndex: "999",
                            }}
                            className="no-drag"
                        // state={tabPanelState}
                        >
                            <IconButton
                                sx={{
                                    display: "flex",
                                    fontFamily: "Montserrat-Regular",
                                    fontSize: "18px",
                                    color: theme.palette.primary.main,
                                    "&:hover": { backgroundColor: "transparent" },
                                    margin: isMobile ? "8px" : "0"
                                }}
                            >
                                <ArrowCircleRightOutlinedIcon
                                    sx={{
                                        marginRight: isMobile ? "2px" : "16px",
                                        color: theme.palette.primary.main,
                                    }}
                                />
                                View More
                            </IconButton>
                        </Link>
                    </Grid>
                );
            case 'CompanyNews':
                return (
                    <div className={classes.companyNewsHeader}>
                        <div className={classes.icon}><FeedOutlinedIcon /></div>
                        <div className={classes.companyNewsHeaderText}>Company News</div>
                    </div>
                );
            case 'ImportantUpdates':
                return (
                    <div className={classes.importantUpdatesHeader}>
                        <div className={classes.icon}><AssignmentOutlinedIcon /></div>
                        <div className={classes.importantUpdatesHeaderText}>Important Updates</div>
                    </div>
                );
            default:
                return (
                    <div className={classes.importantUpdatesHeader}>
                        <div className={classes.icon}><AssignmentOutlinedIcon /></div>
                        <div className={classes.importantUpdatesHeaderText}>{component}</div>
                    </div>
                );
        }
    };

    return (
        <>
            {
                fetched ? (
                    <>
                        {/* <Box className={classes.filterBox}>
                            <IconButton
                                sx={{
                                    display: 'flex',
                                    fontFamily: 'Montserrat-Regular',
                                    fontSize: '18px',
                                    color: theme.palette.primary.main,
                                    '&:hover': { backgroundColor: 'transparent' },
                                }}
                                aria-controls={open ? 'widgets-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={openWidgetDropdown}
                            >
                                <WidgetsIcon
                                    sx={{
                                        marginRight: '15px',
                                        color: theme.palette.primary.main,
                                    }}
                                />
                                Widgets
                            </IconButton>

                            <Menu
                                id="widgets-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={closeWidgetDropdown}
                                PaperProps={{
                                    style: {
                                        maxHeight: '300px',
                                        width: '250px',
                                    },
                                }}
                            >
                                {widgetNames.map((widget) => (
                                    <MenuItem key={widget.id} onClick={() => handleToggle(widget.id)}>
                                        <Checkbox
                                            checked={selectedWidgetsDB.includes(widget.id)}
                                            color="primary"
                                        />
                                        <ListItemText primary={widget.name} />
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box> */}
                        {layoutDB && Array.isArray(layoutDB) && layoutDB.length > 0 ? (<Box className={classes.widgetContent}>
                            <GridLayout
                                className="layout"
                                width={gridWidth}
                                layout={layoutDB}
                                cols={12}
                                rowHeight={100}
                                isDraggable
                                isResizable
                                draggableHandle=".widget-header"
                                draggableCancel=".no-drag"
                                onLayoutChange={onLayoutChange}
                            >
                                {layoutDB
                                    .filter((widget) => selectedWidgetsDB.includes(widget.i) || defaultWidgets.includes(widget.i))
                                    .map((widget) => (
                                        <div key={widget.i} className="widget">
                                            <div className="widget-header">{getComponentHeading(widget.i)}</div>
                                            <div className="widget-content" style={{ overflowY: 'scroll' }}>{getComponent(widget.i)}</div>
                                        </div>
                                    ))}
                            </GridLayout>
                        </Box>) :
                            (<Box className={classes.noWidgetContent}>
                                <h3 style={{ color: theme.palette.secondary.main }}> Select Widgets to display</h3>
                            </Box>)}
                    </>
                ) : <Loader />
            }
        </>
    );
};

export default Widgets;
