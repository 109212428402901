import { createSlice } from "@reduxjs/toolkit";
import { fetchMasterWidgetsData } from "../../actions/MasterWidgets/masterWidgets";

// Slice
const masterWidgetsSlice = createSlice({
    name: 'masterWidgets',
    initialState: {
        masterWidgetLayout: [],
        error: null,
        fetched: false
    },
    // reducers: {
    //     setSelectedWidgetsDB: (state, action) => {
    //         state.selectedWidgetsDB = action.payload;
    //     },
    //     setLayoutDB: (state, action) => {
    //         state.layoutDB = action.payload;
    //     },
    // },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMasterWidgetsData.pending, (state) => {
                state.fetched = false;
            })
            .addCase(fetchMasterWidgetsData.fulfilled, (state, action) => {
                state.fetched = true;
                state.masterWidgetLayout = action.payload;
            })
            .addCase(fetchMasterWidgetsData.rejected, (state, action) => {
                state.fetched = true;
                state.error = action.error.message;
            });
    },
});

// export const { setSelectedWidgetsDB, setLayoutDB } = widgetsSlice.actions;
export default masterWidgetsSlice.reducer;
