import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    trainingDetailItem: null,
};

const trainingDetailSlice = createSlice({
    name: 'trainingDetailPath',
    initialState,
    reducers: {
        setTrainingDetail: (state, action) => {
            state.trainingDetailItem = action.payload.trainingDetailItem;
        },
        clearTrainingDetail: (state) => {
            state.trainingDetailItem = null;
        },
    },
});

export const { setTrainingDetail } = trainingDetailSlice.actions;
export default trainingDetailSlice.reducer;
