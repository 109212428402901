import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { persistor, store } from "./redux/store";
import App from './App';
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication, EventType } from '@azure/msal-browser'
import { msalConfig } from './helper/auth';
import { PersistGate } from 'redux-persist/integration/react';
import theme from "./theme";
import { ThemeProvider } from '@mui/material/styles';

// MSAL should be instantiated outside of the componenet tree to prevent if from being re-instantiated on re-render

const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on the load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
  }
})


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <Router>
        <ThemeProvider theme={theme}>
          <PersistGate loading={null} persistor={persistor}>
            <App instance={msalInstance} />
          </PersistGate>
        </ThemeProvider>
      </Router>
    </Provider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
