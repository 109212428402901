import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getIdToken } from "./../../../helper/auth";
import config from "../../../config";

const hostname = window.location.hostname;

const port = window.location.port;

const portalApiBaseUrl = port ? 'http://' + hostname + ":" + port : 'https://' + hostname;

export const fetchWidgetsData = createAsyncThunk(
    "widgets/fetchWidgetsData",
    async (userId) => {
        try {
            const IdToken = await getIdToken();
            const headers = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${IdToken}`,
                },
            };
            const response = await axios.get(
                `${portalApiBaseUrl}/api/widgets/${userId}`,
                headers
            );
            return response.data;
        } catch (error) {
            console.error("Error fetching widgetsData:", error);
        }
    }
);

export const saveWidgetsData = createAsyncThunk(
    "widgets/saveWidgetsData",
    async ({ userId, selectedWidgets, layout }, { rejectWithValue }) => {
        try {
            const IdToken = await getIdToken();
            const headers = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${IdToken}`,
                },
            };
            const response = await axios.post(
                `${portalApiBaseUrl}/api/widgets`,
                { userId, selectedWidgets, layout },
                headers
            );
            return response.data;
        } catch (error) {
            //throw error;
            // return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);
