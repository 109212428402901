import React, { useState } from "react";
import { IconButton, Drawer, Box, Tooltip } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import CloseIcon from "@mui/icons-material/Close";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import StewBot from "../../components/StewBot/index";
import styles from "./styles";

const StewBotPopOver = () => {
    const [open, setOpen] = useState(false);
    const [clearMessage, setClearMessage] = useState(false);
    const { classes } = styles();

    const handleClearMessageState = (state) => {
        setClearMessage(state);
    };

    return (
        <>
            {/* Floating Chat Button */}
            <IconButton
                onClick={() => setOpen(true)}
                className={classes.chatIcon}
            >
                <ChatIcon sx={{ fontSize: 35 }} />
            </IconButton>

            {/* Chat Drawer */}
            <Drawer
                anchor="right"
                open={open}
                onClose={() => setOpen(false)}
                variant="persistent"
                PaperProps={{
                    sx: {
                        position: "fixed",
                        bottom: 0,
                        right: 0,
                        top: "auto",
                        width: "30%",
                        height: "76vh",
                        padding: "5px",
                        borderRadius: "10px",
                        overflow: "hidden",
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                        zIndex:"999"
                    }
                }}
            >
                <Box className={classes.chatBoxHeader}>
                    <div><h1 style={{ fontSize: '24px', letterSpacing: '2px', lineHeight: '0px' }}>Stewbot</h1></div>
                    <div>
                        <Tooltip placement='top-start' title='Reset Chat'>
                            <IconButton onClick={() => setClearMessage(true)} sx={{ color: "white" }}>
                                <AutorenewIcon sx={{ fontSize: '30px' }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip placement='top-start' title='Minimize Window'>
                            <IconButton onClick={() => setOpen(false)} sx={{ color: "white" }}>
                                <CloseIcon sx={{ fontSize: '30px' }} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </Box>
                <StewBot clearMessage={clearMessage} handleClearMessageState={handleClearMessageState} />
            </Drawer>
        </>
    );
};

export default StewBotPopOver;
