import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getIdToken } from "./../../../helper/auth";
import config from "../../../config";

const hostname = window.location.hostname;

const port = window.location.port;

const portalApiBaseUrl = port ? 'http://' + hostname + ":" + port : 'https://' + hostname;

export const fetchMasterWidgetsData = createAsyncThunk(
    "widgets/fetchMasterWidgetsData",
    async (userRole) => {
        try {
            const IdToken = await getIdToken();
            const headers = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${IdToken}`,
                },
            };
            const response = await axios.get(
                `${portalApiBaseUrl}/api/masterWidgets?role=${userRole}`,
                headers
            );
            return response.data;
        } catch (error) {
            console.error("Error fetching widgetsMasterData:", error);
        }
    }
);
