import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    stewBotPresent: false,
};

const stewBotPresentSlice = createSlice({
    name: 'stewBot',
    initialState,
    reducers: {
        setStewBotStagingPresent: (state, action) => {
            state.stewBotPresent = action.payload.stewBotPresent;
        },
        clearStewBotStagingPresent: (state) => {
            state.stewBotPresent = false;
        },
    },
});

export const { setStewBotStagingPresent } = stewBotPresentSlice.actions;
export default stewBotPresentSlice.reducer;
