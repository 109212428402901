import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "./../../../config";

export const getTrainingDetailData = createAsyncThunk(
    "trainingDetailData",
    async ({ trainingDetailAPI, oAuthToken }, { rejectWithValue }) => {
        try {
            // const accessToken = await getAccessToken();
            const headers = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${oAuthToken}`, //`Bearer ${accessToken}` if access token authorization
                },
            };
            const response = await axios.get(
                `${config.drupalapiBaseUrl}/api/v2${trainingDetailAPI}`,
                headers
            );
            if (response.status === 200) {
                sessionStorage.setItem("refreshDialog", "false");
            }
            return response.data;
        } catch (error) {
            //throw error;
            // return custom error message from backend if present
            if (error.response.status === 401) {
                sessionStorage.setItem("refreshDialog", "true");
            }
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);
