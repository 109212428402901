export const searchFacets = {
  "Category": {
    "Other": "Other",
    "Branding (includes Logos, zoom backgrounds)": "Branding (includes Logos, zoom backgrounds)",
    "Alternative Investments": "Alternative Investments",
    "Form": "Form",
    "Important Updates": "Important Updates",
    "Partnership Communications": "Partnership Communications",
    "Account Opening &amp; Maintenance": "Account Opening & Maintenance",
    "Materials": "Materials",
    "Compliance": "Compliance",
    "Social Media": "Social Media"
  },
  "Content_Type": {
    "marketing": "Marketing",
    "policies_and_procedures": "Policies and Procedures",
    "document": "Document",
    "product": "Product",
    "newsletter": "Newsletter",
    "strategic_partner_program": "Strategic Partner Program",
    "form": "Form",
    "notice_board": "Notice Board",
    "training": "Training",
    "department": "Department"
  },
  "Department": {
    "": "Other",
    "Operations": "Operations",
    "Compliance &amp; Risk": "Compliance & Risk",
    "Marketing": "Marketing",
    "Products": "Products",
    "Human Resources": "Human Resources",
    "Insurance &amp; Annuities": "Insurance & Annuities",
    "Accounting &amp; Finance": "Accounting & Finance",
    "Technology": "Technology"
  },
  "Custodian": {
    "": "Other",
    "Pershing": "Pershing",
    "Raymond James": "Raymond James",
    "RJ &amp; PER": "RJ & PER"
  }
}

  export const convertMachineNamesToDisplayNames=(str)=> {
    if (!str.trim()) {
      return "Other";
    }

    // Replace HTML codes with their corresponding characters
    const htmlEntities = {
"&amp;": "&", // Ampersand
    "&lt;": "<", // Less-than symbol
    "&gt;": ">", // Greater-than symbol
    "&quot;": '"', // Double quote
    "&apos;": "'", // Apostrophe
    "&nbsp;": " ", // Non-breaking space
    "&copy;": "©", // Copyright symbol
    "&reg;": "®", // Registered trademark symbol
    "&eacute;": "é", // e with acute accent
    "&egrave;": "è", // e with grave accent
    "&uuml;": "ü", // u with umlaut
    "&iacute;": "í", // i with acute accent
    "&ntilde;": "ñ", // n with tilde
    "&alpha;": "α", // Greek letter alpha
    "&beta;": "β", // Greek letter beta
    "&pi;": "π", // Greek letter pi
    "&times;": "×", // Multiplication sign
    "&divide;": "÷", // Division sign
    "&dollar;": "$", // Dollar sign
    "&euro;": "€", // Euro sign
    "&pound;": "£", // Pound sterling sign
    "&yen;": "¥", // Yen sign
    "&equals;": "=", // Equals sign
    "&le;": "≤", // Less than or equal to
    "&ge;": "≥", // Greater than or equal to
    "&ndash;": "–", // En dash
    "&mdash;": "—", // Em dash
    "&bull;": "•", // Bullet
    "notice_board":"Communications"
      // You can add more entities as needed
    };
  
    // Replace all HTML entities with corresponding characters
    for (const [entity, char] of Object.entries(htmlEntities)) {
      str = str.replace(new RegExp(entity, 'g'), char);
    }
  
    // Replace underscores with spaces
    str = str.replace(/_/g, " ");
  
    // Capitalize the first letter of each word
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }
