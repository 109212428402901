import React from 'react';
// import ReactDOMServer from 'react-dom/server';
import styles from "./styles";
import { useNavigate } from "react-router-dom";
import { Box } from '@mui/material';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import ReactPlayer from 'react-player/lazy';
import AttachmentViewer from '../../../components/AttachmentViewer';

export default function TrainingDetailPage({ content, attachment }) {
    const { classes } = styles();
    const navigate = useNavigate();

    const sanitizedHTML = DOMPurify.sanitize(content ? content : '');
    // Parse the HTML and replace video URLs with <VideoViewer>
    const parsedContent = parse(sanitizedHTML, {
        replace: domNode => {
            // Remove specific <div class="field__label visually-hidden">Image</div>
            if (
                domNode.name === 'div' &&
                domNode.attribs &&
                domNode.attribs.class == "field__label visually-hidden" &&
                domNode.children &&
                domNode.children[0] &&
                domNode.children[0].data === 'Image'
            ) {
                return <div></div>;
            }

            // Check if the element is a video link (e.g., mp4 or other video formats)
            if (
                domNode.name === 'a' &&
                domNode.attribs &&
                /\.(mp4|webm|ogg)$|youtube\.com/.test(domNode.attribs.href)
            ) {
                return (
                    <Box xs={12} sx={{ padding: '0px 60px 60px 60px' }}>
                        <div className='player-wrapper'>
                            <ReactPlayer
                                url={domNode.attribs.href}
                                controls
                                className='react-player'
                                width='100%'
                                height='100%'
                            />
                        </div>
                    </Box>
                );
            }
            return undefined; // Render all other elements as is
        },
    });

    //Code to console and see Parsed content as normal String
    // const parsedContentAsString = ReactDOMServer.renderToStaticMarkup(parsedContent);
    // console.log("Parsed Content as String:", parsedContentAsString);

    const handleLinkClick = (e) => {
        e.preventDefault();
        const target = e.target.closest("a");
        if (!target || !target.href) return;

        const href = target.getAttribute("href");
        // Determine link type and handle accordingly
        if (
            href.startsWith("http") ||
            href.startsWith("https") ||
            href.startsWith("www") ||
            href.startsWith("//")
        ) {
            window.open(href, "_blank");
        } else if (href.startsWith("mailto")) {
            window.location.href = href;
        } else {
            navigate(href, { replace: false });
        }
    };

    return (
        <>
            <Box className={classes.trainualDynamicHtmlBox}>
                <span
                    onClick={(e) => {
                        const target = e.target.closest("a");
                        if (target && target.href) {
                            handleLinkClick(e);
                        }
                    }}
                >{parsedContent}</span>
            </Box>
            {attachment && <AttachmentViewer attachment={attachment} />}
        </>
    );
};