import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme) => {
  return {
    icon: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      borderRadius: theme.spacing(5),
      width: "48px",
      height: "48px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    importantUpdatesBox: {
      // borderRadius: "8px",
      // border: `1px solid ${theme.palette.common.outline} !important`,
      background: theme.palette.common.white,
      maxHeight: "850px",
      overflow: "scroll !important",
    },
    importantUpdatesHeader: {
      display: "inline-flex",
      alignItems: "center",
      gap: theme.spacing(2),
      width: "100%",
      padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(2)} !important`,
    },
    importantUpdatesHeaderText: {
      color: theme.palette.secondary.main,
      fontFamily: "Oswald-Regular",
      fontSize: "32px",
      fontWeight: "600",
      lineHeight: "38px",
    },
    title: {
      color: theme.palette.info.dark,
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: "Oswald-Regular !important",
      fontSize: "16px !important",
      fontWeight: "700 !important",
      lineHeight: "24px !important",
      marginBottom: `${theme.spacing(1)} !important`,
    },
    description: {
      color: theme.palette.info.main,
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: "Montserrat-Regular !important",
      fontSize: "18px !important",
      fontWeight: "300 !important",
      lineHeight: "32px !important",
      "& span": {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
      },
      "& p": {
        color: theme.palette.info.main,
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Montserrat-Regular !important",
        fontSize: "18px !important",
        fontWeight: "300 !important",
        lineHeight: "32px !important",
      },
    },
    custom_editor: {
      "& p": {
        color: theme.palette.info.main,
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Montserrat-Regular !important",
        fontSize: "18px !important",
        fontWeight: "300 !important",
        lineHeight: "32px !important",
      },
    },
    linkText: {
      color: theme.palette.primary.main,
      textDecoration: "none",
    }
  }
}
);

export default styles;
