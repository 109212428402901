import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    trainingListItem: null,
};

const trainingListPathSlice = createSlice({
    name: 'trainingListPath',
    initialState,
    reducers: {
        setTrainingList: (state, action) => {
            state.trainingListItem = action.payload.trainingListItem;
        },
        clearTrainingList: (state) => {
            state.trainingListItem = null;
        },
    },
});

export const { setTrainingList } = trainingListPathSlice.actions;
export default trainingListPathSlice.reducer;
