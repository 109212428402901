import * as React from 'react';
import { Breadcrumbs, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Link, useNavigate } from "react-router-dom";

export default function Breadcrumb(props) {
    const { subjectTitle, topicTitle } = props;
    const navigate = useNavigate();

    return (
        <Stack spacing={2}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Link to={'/'} underline="hover" key="1" color="inherit">
                    Home
                </Link>
                <Typography
                    underline="hover"
                    key="2"
                    color="inherit"
                    onClick={() => topicTitle ? navigate(-1) : null}
                    style={{ cursor: topicTitle ? 'pointer' : null }}
                >
                    {subjectTitle ? subjectTitle.replace(/&amp;/gi, '&') : ''}
                </Typography>
                {topicTitle && <Typography key="3" sx={{ color: 'text.primary' }}>
                    {topicTitle ? topicTitle.replace(/&amp;/gi, '&') : ''}
                </Typography>}
            </Breadcrumbs>
        </Stack>
    );
}
