import React, { useState, useEffect, useMemo } from "react";
import "./style.scss";
import { Menu, MenuItem, useMediaQuery, Toolbar, AppBar, Badge, Box, Checkbox, ListItemText, Typography, IconButton } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import MoreIcon from "@mui/icons-material/MoreVert";
import Logo from "./../../assets/images/steward-logo.svg";
import LogoBlue from "./../../assets/images/steward-Logo-blue.svg";
import MobileLogo from "./../../assets/images/sp-Mobile-Logo.svg";
import MobileLogoBlue from "./../../assets/images/sp-Mobile-Logo-blue.svg";
import { useTheme } from "@mui/material/styles";
import Logout from "@mui/icons-material/Logout";
import ListItemIcon from "@mui/material/ListItemIcon";
import BasicMenu from "./../Menu";
import navLinksData from "./../../assets/json/mega-menu.json";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import FullScreenDialog from "./../Dialog";
import Search from "../Search";
import FavoritePage from "../../views/FavoriteApps/FavoritePage/index";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { useMsal } from "@azure/msal-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Notifications from "../Notifications";
import { useDispatch, useSelector } from 'react-redux';
import { Divider, InputBase, Paper } from "@mui/material";
import HelpdeskDialog from "../Dialog/HelpdeskDialog";
import WidgetsIcon from '@mui/icons-material/Widgets';
import { setSelectedWidgetsDB, setLayoutDB } from '../../redux/reducers/Widgets/widgetSlice';
import { saveWidgetsData } from '../../redux/actions/Widgets/widgets';
import { fetchMasterWidgetsData } from "../../redux/actions/MasterWidgets/masterWidgets";

export default function Header({ position, top, setSearchDialogState, handleUpdateUrl, trainualPage }) {
  const { instance, inProgress } = useMsal();
  const broadcastChannel = new BroadcastChannel("msal_logout_channel");
  const theme = useTheme();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [notificationsAnchor, setNotificationsAnchor] = useState(null);
  const location = useLocation();
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const { userData } = useSelector((state) => state.userData);
  const masterWidgetLayout = useSelector((state) => state.masterWidgets?.masterWidgetLayout?.[0]?.layout) || [];
  const userIdHeaderFromRedux = useSelector((state) => state.userData?.userData?.id);
  const [userIdHeader, setUserIdHeader] = useState(null);
  const isMobile = useMediaQuery("(max-width:768px)");
  const navigate = useNavigate();
  const { selectedWidgetsDB, layoutDB, fetched } = useSelector((state) => state.widgets);
  const [layout, setLayout] = useState(layoutDB);
  const [selectedWidgets, setSelectedWidgets] = useState(selectedWidgetsDB);
  const [anchorElWidget, setAnchorElWidget] = useState(null);
  const open = Boolean(anchorElWidget);
  const openWidgetDropdown = (event) => setAnchorElWidget(event.currentTarget);
  const closeWidgetDropdown = () => setAnchorElWidget(null);

  const userRole = "employee"
  const defaultWidgets = ['Favorites', 'ImportantUpdates', 'CompanyNews'];

  useEffect(() => {
    dispatch(fetchMasterWidgetsData(userRole));
  }, []);

  const roleBasedLayouts = {
    admin: [
      { i: 'Favorites', x: 0, y: 0, w: 12, h: 3, minW: 6, minH: 2, static: false, isResizable: true },
      { i: 'CompanyNews', x: 0, y: 3, w: 6, h: 4, minW: 4, minH: 2, static: false, isResizable: true },
      { i: 'ImportantUpdates', x: 6, y: 3, w: 6, h: 4, minW: 4, minH: 2, static: false, isResizable: true },
      { i: 'AdminWidget1', x: 0, y: 7, w: 12, h: 4, minW: 6, minH: 2, static: false, isResizable: true },
      { i: 'AdminWidget2', x: 0, y: 7, w: 12, h: 4, minW: 4, minH: 2, static: false, isResizable: true }
    ],
    manager: [
      { i: 'Favorites', x: 0, y: 0, w: 12, h: 3, minW: 6, minH: 2, static: false, isResizable: true },
      { i: 'CompanyNews', x: 0, y: 3, w: 6, h: 4, minW: 4, minH: 2, static: false, isResizable: true },
      { i: 'ImportantUpdates', x: 6, y: 3, w: 6, h: 4, minW: 4, minH: 2, static: false, isResizable: true },
      { i: 'managerWidget1', x: 0, y: 7, w: 6, h: 4, minW: 4, minH: 2, static: false, isResizable: true },
      { i: 'managerWidget2', x: 6, y: 7, w: 6, h: 4, minW: 4, minH: 2, static: false, isResizable: true }
    ],
    employee: [
      { i: 'Favorites', x: 0, y: 0, w: 12, h: 3, minW: 6, minH: 2, static: false, isResizable: true },
      { i: 'CompanyNews', x: 0, y: 3, w: 6, h: 4, minW: 4, minH: 2, static: false, isResizable: true },
      { i: 'ImportantUpdates', x: 6, y: 3, w: 6, h: 4, minW: 4, minH: 2, static: false, isResizable: true }
    ]
  };

  useEffect(() => {
    if (userIdHeader) {
      localStorage.setItem("userIdHeader", userIdHeader);
    }
  }, [userIdHeader]);

  useEffect(() => {
    // Fallback to localStorage if userId is not in Redux
    const localStorageUserIdHeader = localStorage.getItem("userIdHeader");
    setUserIdHeader(userIdHeaderFromRedux || localStorageUserIdHeader);
  }, [userIdHeaderFromRedux]);

  // Define initial layouts and widget configurations
  // const initialLayout = [
  //   { i: 'Favorites', x: 0, y: 0, w: 12, h: 3, minW: 6, minH: 2, static: false, isResizable: true },
  //   { i: 'CompanyNews', x: 0, y: 1, w: 6, h: 4, minW: 4, minH: 2, static: false, isResizable: true },
  //   { i: 'ImportantUpdates', x: 6, y: 1, w: 6, h: 4, minW: 4, minH: 2, static: false, isResizable: true }
  // ];

  // const initialLayout = useMemo(() => roleBasedLayouts[userRole] || [], [userRole]);

  const initialLayout = useMemo(() => (masterWidgetLayout.length > 0 ? masterWidgetLayout : roleBasedLayouts[userRole]) || [], [masterWidgetLayout, roleBasedLayouts]);

  // console.log("initialLayout", initialLayout);
  // console.log("userRole", userRole);

  const widgetNames = useMemo(() => initialLayout.map(widget => ({
    id: widget.i,
    name: widget.i
  })), [initialLayout]);

  const rebuildLayout = (updatedWidgets) => {
    const activeLayout = updatedWidgets?.map((widgetId) =>
      layoutDB.find((widget) => widget.i === widgetId) || initialLayout.find((widget) => widget.i === widgetId)
      // initialLayout.find((widget) => widget.i === widgetId)
    );
    setLayout(activeLayout);
    dispatch(setLayoutDB(activeLayout));
  };

  const handleToggle = (widgetId) => {
    let updatedWidgets;

    if (defaultWidgets.includes(widgetId)) {
      // If the widget is in the default list, prevent it from being deselected
      return;
    }

    updatedWidgets = selectedWidgetsDB.includes(widgetId)
      ? selectedWidgetsDB.filter((id) => id !== widgetId)
      : [...selectedWidgetsDB, widgetId];

    // Ensure default widgets are always included
    updatedWidgets = [...new Set([...defaultWidgets, ...updatedWidgets])];

    setSelectedWidgets(updatedWidgets);
    dispatch(setSelectedWidgetsDB(updatedWidgets));

    // Rebuilds the layout based on selectedWidgets
    rebuildLayout(updatedWidgets);

    // Restore full layout from layoutDB for widgets that are re-enabled
    // Initiallayout is used as a callback if layoutDB is missing
    // const updatedLayout = initialLayout.filter((widget) =>
    //     updatedWidgets.includes(widget.i)
    // );

    const updatedLayout = updatedWidgets.map((widgetId) =>
      layoutDB.find((widget) => widget.i === widgetId) ||
      initialLayout.find((widget) => widget.i === widgetId)
    );

    setLayout(updatedLayout);
    dispatch(setLayoutDB(updatedLayout));

    if (userIdHeader && updatedWidgets.length === 0) {
      dispatch(saveWidgetsData({ userId: userIdHeader, selectedWidgets: updatedWidgets, layout: updatedLayout }));
    }
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationsOpen = (event) => {
    setNotificationsAnchor(event.currentTarget);
  };

  const handleNotificationsClose = () => {
    setNotificationsAnchor(null);
  };

  const notificationsOpen = Boolean(notificationsAnchor);
  const notificationId = notificationsOpen ? "simple-popover" : "no-popover";

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [whiteHeaderFlag, setWhiteHeaderFlag] = useState(true);
  const [staticWhiteHeaderFlag, setStaticWhiteHeaderFlag] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [logoSrc, setLogoSrc] = useState(Logo); // Initially set to white logo
  const updateWhiteHeader = (flag) => {
    setWhiteHeaderFlag(flag);
  };

  const changeHeaderOnOver = () => {
    if (!scrolled) {
      setStaticWhiteHeaderFlag(true);
    }
  };

  const changeHeaderOnLeave = () => {
    if (!scrolled) {
      setStaticWhiteHeaderFlag(false);
    }
  };

  const correctHeader = () => {
    if (whiteHeaderFlag === false) {
      setWhiteHeaderFlag(true);
    }
  };

  const handleLogout = () => {
    sessionStorage.setItem("closedAlerts", JSON.stringify([]));
    localStorage.clear();
    sessionStorage.removeItem("sessionDialog");
    sessionStorage.removeItem("refreshDialog");
    navigate("/");
    instance.logout(); // Sign out the user
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [openFavDialog, setOpenFavDialog] = useState(false);
  const [openHelpDeskDialog, setOpenHelpDeskDialog] = useState(false);
  const [favorites, setFavorites] = useState(false);

  useEffect(() => {
    setSearchDialogState && setSearchDialogState(openDialog);
  }, [openDialog]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenFavDialog = () => {
    setOpenFavDialog(true);
    setFavorites(true);
  };

  const handleCloseFavDialog = () => {
    setOpenFavDialog(false);
  };

  const handleHelpdeskIconClick = () => {
    // window.open(
    //   "https://support.jira.stewardpartners.com/servicedesk/customer/portals",
    //   "_blank"
    // );
    setOpenHelpDeskDialog(true)
  }

  const handleClick = () => {
    // Check if the current location is already the home page
    if (location.pathname === "/") {
      window.scrollTo(0, 0); // Scrolls to the top of the page
    }
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={isMenuOpen}
      onClose={handleMenuClose}
      onClick={handleMenuClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&::before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 23,
            width: 10,
            height: 11,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem
        onClick={() => {
          sessionStorage.setItem("closedAlerts", JSON.stringify([]));
          localStorage.removeItem("oAuthToken");
          handleLogout();
        }}
      >
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="search" color="primary" id="headerItem1">
          {/* <SearchIcon /> */}
          <Paper
            component="form"
            sx={{
              p: "2px 2px",
              display: "flex",
              alignItems: "center",
              width: 50,
              borderRadius: 8
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search.."
              inputProps={{ "aria-label": "search" }}
            />
            <IconButton size="large" aria-label="search" color="#0C3752" sx={{ padding: "8px" }}>
              <SearchIcon />
            </IconButton>
          </Paper>
        </IconButton>

        <p>Messages</p>
      </MenuItem>
      <MenuItem onClick={handleNotificationsOpen}>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
          id="headerItem2"
        >
          <Badge badgeContent={17} color="error">
            <HelpOutlineOutlinedIcon />
          </Badge>
        </IconButton>
        <p>Support</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
          id="headerItem3"
        >
          <NotificationsOutlinedIcon />
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          id="headerItem4"
        >
          <AccountCircleOutlinedIcon />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup function
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled, whiteHeaderFlag, location]);

  useEffect(() => {
    setLogoSrc(
      !location.pathname.startsWith("/Search") && whiteHeaderFlag && !scrolled
        ? (isMobile ? MobileLogo : Logo)
        : (isMobile ? MobileLogo : Logo)
    );
  });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        //position={position}
        position={whiteHeaderFlag && !scrolled ? position : "fixed"}
        color={whiteHeaderFlag && !scrolled ? "transparent" : ""}
        elevation={whiteHeaderFlag ? 0 : 3}
        sx={{
          "& .MuiToolbar-root": {
            paddingLeft: "32px",
            paddingRight: "32px",
            minHeight: "0px",
            paddingTop: isMobile ? "18px" : "20px",
            paddingBottom: "10px",
            alignItems: "center"
          },
          top: whiteHeaderFlag && !scrolled ? top : "0px",
        }}
        className={whiteHeaderFlag && !scrolled ? "" : "header-shadow"}
        onClick={correctHeader}
        onMouseOver={changeHeaderOnOver}
        onMouseLeave={changeHeaderOnLeave}
      >
        <Toolbar>
          {isMobile && <BasicMenu
            trainualPage={trainualPage}
            navLinksData={navLinksData}
            updateWhiteHeader={updateWhiteHeader}
            whiteHeaderFlag={whiteHeaderFlag}
            scrolled={scrolled}
            staticWhiteHeaderFlag={staticWhiteHeaderFlag}
            location={location}
            handleUpdateUrl={handleUpdateUrl}
          />}
          <Typography
            variant="h6"
            noWrap
            component="div"
            align="left"
          // sx={{ display: { xs: "none", sm: "block" } }}
          >
            <Link to="/" underline="none" onClick={handleClick}>
              <img
                alt="Steward Partners Logo"
                src={logoSrc}
                style={{
                  width: isMobile ? "94%" : whiteHeaderFlag && !scrolled ? "70%" : "70%"
                }}
              />
            </Link>
          </Typography>

          <Box sx={{ flexGrow: 1 }} />
          {/* <Box onClick={handleOpenFavDialog} sx={{ display: 'flex', paddingRight: '14px' }}>
            <IconButton sx={{fontFamily:'Montserrat-Regular'}} disableRipple>
              <FavoriteIcon sx={{ color: theme.palette.primary.main }} /><div style={{ fontSize: '14px', paddingLeft: '10px', color: ((location === "/" || location === "/detail") || (whiteHeaderFlag && !scrolled)) ? theme.palette.common.white : theme.palette.common.grey }}>Favorites</div>
            </IconButton>
          </Box> */}
          <Box sx={{ display: { xs: "flex", md: "flex" } }}>
            {!isMobile && (
              <BasicMenu
                navLinksData={navLinksData}
                updateWhiteHeader={updateWhiteHeader}
                whiteHeaderFlag={whiteHeaderFlag}
                scrolled={scrolled}
                staticWhiteHeaderFlag={staticWhiteHeaderFlag}
                location={location}
                handleUpdateUrl={handleUpdateUrl}
              />
            )}
            {location.pathname === '/' && <IconButton
              sx={{
                display: 'flex',
                fontFamily: 'Montserrat-Regular',
                fontSize: '18px',
                color: theme.palette.primary.main,
                '&:hover': { backgroundColor: 'transparent' },
                // padding: '0px 12px 12px !important'
              }}
              aria-controls={open ? 'widgets-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={openWidgetDropdown}
              id="headerItem1"
            >
              <WidgetsIcon
                sx={{
                  // marginRight: '15px',
                  color: theme.palette.common.white,
                }}
              />
            </IconButton>}

            <Menu
              id="widgets-menu"
              anchorEl={anchorElWidget}
              open={open}
              onClose={closeWidgetDropdown}
              PaperProps={{
                style: {
                  maxHeight: '300px',
                  width: '250px',
                },
              }}
            >
              {widgetNames.map((widget) => (
                <MenuItem key={widget.id} onClick={() => handleToggle(widget.id)}>
                  <Checkbox
                    checked={selectedWidgetsDB.includes(widget.id)}
                    color="primary"
                    disabled={defaultWidgets.includes(widget.id)}
                  />
                  <ListItemText primary={widget.name} />
                </MenuItem>
              ))}
            </Menu>
            <IconButton
              size="large"
              aria-label="show 4 new mails"
              color="inherit"
              onClick={handleOpenDialog}
              id="headerItem1"
            >
              {/* {isMobile ? */}
              <SearchIcon
                fontSize="large"
                style={{
                  color:
                    !location.pathname.startsWith("/Search") &&
                      whiteHeaderFlag &&
                      !scrolled
                      ? theme.palette.common.white
                      : theme.palette.common.white
                }}
              />
              {/* : 

                <Paper
                  component="form"
                  sx={{
                    p: "2px 2px",
                    display: "flex",
                    alignItems: "center",
                    width: 150,
                    borderRadius: 8
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search.."
                    inputProps={{ "aria-label": "search" }}
                  />
                  <IconButton size="large" aria-label="search" color="#0C3752" sx={{ padding: "8px" }}>
                    <SearchIcon />
                  </IconButton>
                </Paper>}*/}
            </IconButton>
            <IconButton
              size="large"
              aria-label="show 4 new mails"
              color="inherit"
              id="headerItem2"
              onClick={handleHelpdeskIconClick}
            >
              <HelpOutlineOutlinedIcon
                fontSize="large"
                style={{
                  color:
                    !location.pathname.startsWith("/Search") &&
                      whiteHeaderFlag &&
                      !scrolled
                      ? theme.palette.common.white
                      : theme.palette.common.white
                }}
              />
            </IconButton>
            {/* <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={handleNotificationsOpen}
            >
              <NotificationsOutlinedIcon
                fontSize="large"
                style={{
                  color:
                    !location.pathname.startsWith("/Search") &&
                      whiteHeaderFlag &&
                      !scrolled
                      ? theme.palette.common.white
                      : theme.palette.common.grey,
                }}
              />
            </IconButton> */}
            {isMobile ? <IconButton disabled={true}><Divider orientation="vertical" variant="middle" sx={{
              backgroundColor: !location.pathname.startsWith("/Search") &&
                whiteHeaderFlag &&
                !scrolled
                ? theme.palette.common.white
                : theme.palette.common.white, marginTop: '4px', marginBottom: '16px'
            }} aria-hidden="true" /></IconButton> : <IconButton
              id="headerItem7"
              sx={{
                fontFamily: "Montserrat-Regular",
                fontSize: "18px",
                paddingLeft: "20px",
                color:
                  !location.pathname.startsWith("/Search") &&
                    whiteHeaderFlag &&
                    !scrolled
                    ? theme.palette.common.white
                    : theme.palette.common.white,
                "&:hover": {
                  backgroundColor: "transparent",
                  cursor: "default",
                },
              }}
            >
              Hello, {userData.givenName}
            </IconButton>}
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              id="headerItem5"
            >
              <AccountCircleOutlinedIcon
                fontSize="large"
                style={{
                  color:
                    !location.pathname.startsWith("/Search") &&
                      whiteHeaderFlag &&
                      !scrolled
                      ? theme.palette.common.white
                      : theme.palette.common.white
                }}
              />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "none", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="primary"
              id="headerItem6"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <FullScreenDialog
        open={openDialog}
        onClose={handleCloseDialog}
        title={
          <div
            style={{
              color: theme.palette.secondary.main,
              textAlign: "center",
              fontFamily: "Oswald-Regular",
              fontSize: "32px",
              fontWeight: "500",
              lineHeight: "36px",
              letterSpacing: "0.1px",
            }}
          >
            What would you like to find out today?
          </div>
        }
        content={<Search setOpenDialog={setOpenDialog} />}
      >
        {/* Content inside the dialog */}
      </FullScreenDialog>
      <FullScreenDialog
        open={openFavDialog}
        onClose={handleCloseFavDialog}
        title={"Favorites"}
        content={<FavoritePage />}
        favorites={favorites}
      />
      {/* <Notifications
        id={notificationId}
        open={notificationsOpen}
        anchorEl={notificationsAnchor}
        onClose={handleNotificationsClose}
      /> */}
      {openHelpDeskDialog && <HelpdeskDialog
        open={openHelpDeskDialog}
        setOpen={setOpenHelpDeskDialog}
      />}
    </Box>
  );
}
