import { createSlice } from "@reduxjs/toolkit";
import { fetchWidgetsData } from "../../actions/Widgets/widgets";


// Slice
const widgetsSlice = createSlice({
    name: 'widgets',
    initialState: {
        selectedWidgetsDB: [],
        layoutDB: [],
        error: null,
        fetched: false
    },
    reducers: {
        setSelectedWidgetsDB: (state, action) => {
            state.selectedWidgetsDB = action.payload;
        },
        setLayoutDB: (state, action) => {
            state.layoutDB = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchWidgetsData.pending, (state) => {
                state.fetched = false;
            })
            .addCase(fetchWidgetsData.fulfilled, (state, action) => {
                state.fetched = true;
                state.selectedWidgetsDB = action.payload && action.payload[0]?.selectedWidgets ? action.payload[0].selectedWidgets : [];
                state.layoutDB = action.payload && action.payload[0]?.layout ? action.payload[0].layout : [];
                // console.log("PAYLOAD", action.payload);                
            })
            .addCase(fetchWidgetsData.rejected, (state, action) => {
                state.fetched = true;
                state.error = action.error.message;
            });
    },
});

export const { setSelectedWidgetsDB, setLayoutDB } = widgetsSlice.actions;
export default widgetsSlice.reducer;
