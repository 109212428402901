import axios from "axios";
import { getIdToken, getAccessToken } from "./auth";
import config from "../config";

const hostname = window.location.hostname;

const port = window.location.port;

const portalApiBaseUrl = port ? 'http://' + hostname + ":" + port : 'https://' + hostname;

export const fetchStewBotChat = async (userInput) => {
    try {
        const IdToken = await getIdToken();
        const headers = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${IdToken}`,
            },
        };
        const response = await axios.post(`${portalApiBaseUrl}/api/stewbot`, { userInput }, headers);
        return response.data;
    } catch (error) {
        console.error("StewBot chat failed:", error?.response?.data || error?.message);
        throw new Error("Unable to connect to the chat service. Please try again.");
    }
}
