// searchSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedValues: {},
  checkboxStates: {}, // Object to store checkbox checked states
};

const searchFilterSlice = createSlice({
  name: "searchFilter",
  initialState,
  reducers: {
    addSelectedValue: (state, action) => {
      const { filterType, value } = action.payload;

      // If the filterType already exists, add the value to the array
      if (state.selectedValues[filterType]) {
        if (!state.selectedValues[filterType].includes(value)) {
          state.selectedValues[filterType].push(value);
        }
      } else {
        // If filterType doesn't exist, create the filterType key with an array of values
        state.selectedValues[filterType] = [value];
      }
    },
    removeSelectedValue: (state, action) => {
      const { filterType, value } = action.payload;

      // If the filterType exists, remove the value from the array
      if (state.selectedValues[filterType]) {
        const index = state.selectedValues[filterType].indexOf(value);
        if (index !== -1) {
          state.selectedValues[filterType].splice(index, 1);
        }
        
        // If the array is empty after removal, delete the filterType
        if (state.selectedValues[filterType].length === 0) {
          delete state.selectedValues[filterType];
        }
      }
    },
    toggleCheckboxState: (state, action) => {
      const { filterType, value } = action.payload;

      // Toggle the checkbox state based on filterType and value
      if (!state.checkboxStates[filterType]) {
        state.checkboxStates[filterType] = {};
      }
      state.checkboxStates[filterType][value] = !state.checkboxStates[filterType][value];
    },
    initializeCheckboxStates: (state, action) => {
      state.checkboxStates = action.payload;
    },
    clearSelectedValues(state) {
      state.selectedValues = {};
    },
  },
});

export const {
  addSelectedValue,
  removeSelectedValue,
  toggleCheckboxState,
  initializeCheckboxStates,
  clearSelectedValues
} = searchFilterSlice.actions;

export default searchFilterSlice.reducer;
