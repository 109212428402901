import { createSlice } from '@reduxjs/toolkit'
import { getTrainingListData } from '../../actions/TrainingList/trainingList';

const initialState = {
    loading: true,
    error: false,
    trainingList: [],
    message: null,
};
const trainingListSlice = createSlice({
    name: 'trainingList',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //Get Notice
        builder.addCase(getTrainingListData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getTrainingListData.fulfilled, (state, action) => {
            state.loading = false;
            state.trainingList = action.payload;
            state.error = "";
        });
        builder.addCase(getTrainingListData.rejected, (state, action) => {
            state.loading = false;
            state.trainingList = [];
            state.error = action.error.message;
        });
    }
})

export default trainingListSlice.reducer

