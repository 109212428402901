import React, { useRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from "../Header/Index";
import Footer from "../Footer";
import styles from './styles';
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../../redux/actions/userData/userData";
import { getAccessToken, getIdToken } from "./../../../src/helper/auth";

const style = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    position: 'relative',
    width: '100%',
    flex: 1,
    minHeight: '600px',
    display: 'flex',
    flexDirection: 'column',
  },
  iframe: {
    border: 'none',
    width: '100%',
    height: '100%',
    minHeight: '600px',
    flex: 1,
  },
};

const IFRAME_ORIGIN = process.env.REACT_APP_IFRAME_URL;
const DASHBOARD_URL = `${IFRAME_ORIGIN}/dashboard`;

const WorkflowDashboard = () => {
  const { classes } = styles();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.userData);
  const iframeRef = useRef(null);
  const [accessToken, setAccessToken] = useState('');
  const [idToken, setIdToken] = useState('');
  const [tokenSent, setTokenSent] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  
  // Get route parameters
  const { processId, id, form } = useParams();

  // Fetch initial tokens
  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const [access, id] = await Promise.all([
          getAccessToken(),
          getIdToken()
        ]);
        
        if (access) {
          setAccessToken(access);
        }
        if (id) {
          setIdToken(id);
        }
      } catch (error) {
        console.error('Failed to fetch tokens:', error);
      }
    };

    fetchTokens();
  }, []);

  // Function to refresh tokens
  const refreshTokens = async () => {
    if (isRefreshing) {
      return null;
    }

    try {
      setIsRefreshing(true);
      const [newAccessToken, newIdToken] = await Promise.all([
        getAccessToken(),
        getIdToken()
      ]);
      
      if (newAccessToken) {
        setAccessToken(newAccessToken);
      }
      if (newIdToken) {
        setIdToken(newIdToken);
      }
      
      return { accessToken: newAccessToken, idToken: newIdToken };
    } catch (error) {
      console.error('Failed to refresh tokens:', error);
      return null;
    } finally {
      setIsRefreshing(false);
    }
  };

  // Function to send tokens and route parameters to iframe
  const sendTokensToIframe = (tokens = { accessToken, idToken }) => {
    const iframe = iframeRef.current;
  
    if (!iframe?.contentWindow) {
      console.error('Iframe not found or contentWindow not accessible');
      return;
    }
  
    if (!tokens.accessToken || !tokens.idToken) {
      console.error('One or both tokens not available to send');
      return;
    }
  
    try {
      const message = {
        type: 'AUTH_TOKEN',
        payload: {
          accessToken: tokens.accessToken,
          idToken: tokens.idToken,
          timestamp: Date.now(),
          // Include route parameters if they exist
          ...(processId && id && form && {
            processId,
            taskId: id,
            form
          })
        }
      };
  
      iframe.contentWindow.postMessage(message, IFRAME_ORIGIN);
      console.log('Tokens and route parameters sent to iframe successfully');
      setTokenSent(true);
    } catch (error) {
      console.error('Failed to send data to iframe:', error);
    }
  };

  // Handle messages from iframe
  useEffect(() => {
    const handleIframeMessage = async (event) => {
      if (event.origin !== IFRAME_ORIGIN) {
        console.warn('Received message from unauthorized origin:', event.origin);
        return;
      }
  
      try {
        const { type } = event.data;
        
        switch (type) {
          case 'IFRAME_READY':
            if (accessToken && idToken) {
              sendTokensToIframe();
            }
            break;
            
          case 'TOKEN_REFRESH_NEEDED':
            console.log('Token refresh requested by iframe');
            const newTokens = await refreshTokens();
            if (newTokens) {
              sendTokensToIframe(newTokens);
            }
            break;
            
          case 'TOKEN_RECEIVED':
            console.log('Tokens receipt confirmed by iframe');
            break;
        }
      } catch (error) {
        console.error('Error processing iframe message:', error);
      }
    };
  
    window.addEventListener('message', handleIframeMessage);
  
    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, [accessToken, idToken, tokenSent, processId, id, form]);

  // Handle iframe resize
  useEffect(() => {
    const handleIframeResize = () => {
      if (iframeRef.current) {
        const iframe = iframeRef.current;
        try {
          iframe.style.height = '100vh';
        } catch (e) {
          console.log('Unable to resize iframe dynamically:', e);
        }
      }
    };

    window.addEventListener('resize', handleIframeResize);
    if (iframeRef.current) {
      iframeRef.current.addEventListener('load', handleIframeResize);
    }

    return () => {
      window.removeEventListener('resize', handleIframeResize);
      if (iframeRef.current) {
        iframeRef.current.removeEventListener('load', handleIframeResize);
      }
    };
  }, []);

  return (
    <div style={style.wrapper}>
      <div style={style.header}>
        <Header />
      </div>
      <main style={style.main}>
        <div style={style.container}>
          <iframe
            ref={iframeRef}
            src={DASHBOARD_URL}
            style={style.iframe}
            title="Workflow Dashboard"
            sandbox="allow-forms allow-scripts allow-same-origin allow-downloads allow-popups"
            loading="lazy"
          />
        </div>
      </main>
      <div style={style.footer}>
        <Footer id="footer-detail" />
      </div>
    </div>
  );
};

export default WorkflowDashboard;