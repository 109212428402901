import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../../config";

export const getFeedback = createAsyncThunk(
  "webform_rest/feedback_form/fields?_format=json",
  async (oAuthToken, { rejectWithValue }) => {
    try {
      const headers = {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Basic ${basicAuthString}`, //`Bearer ${accessToken}`
          Authorization: `Bearer ${oAuthToken}`, //`Bearer ${accessToken}`
        },
      };
      const response = await axios.get(
        `${config.drupalapiBaseUrl}/webform_rest/feedback_form/fields?_format=json`,
        headers
      );
      
      if (response.status === 200) {
        sessionStorage.setItem("refreshDialog", "false");
      }

      return response.data.feedback_form;
    } catch (error) {
      //throw error;
      // return custom error message from backend if present
      if (error.response.status === 401) {
        sessionStorage.setItem("refreshDialog", "true");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
