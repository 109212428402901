import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "./../../../config";
import { getIdToken } from "../../../helper/auth";

const hostname = window.location.hostname;

const port = window.location.port;

const portalApiBaseUrl = port ? 'http://'+hostname + ":" + port : 'https://'+hostname;

export const getSearch = createAsyncThunk(
  "/api/search",
  async ({ searchQuery, page, filterQuery, rolesArray }, { rejectWithValue }) => {
    try {
      const IdToken = await getIdToken();
      const headers = {
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${IdToken}`,
          },
      };
          // Regex to handle empty search queries
    const searchQueryRegex = /(^''$|^""$|^'$|^"$)/g;
    const matches = searchQuery && searchQuery.match(searchQueryRegex);

    const pageSize = 50;
    const skip = page * pageSize;
      
      let checkRoles = [
        "SG_SSO-Employee_Role",
        "SG_SSO-Affiliate_Role",
        "SG_SSO-Contractor_Role",   
      ];
  
      let currentUserRole = checkRoles.find((value) =>
        rolesArray?.includes(value)
      );

    // Construct the search query body
    const bodyData = {
      search: matches ? "*" : searchQuery,
      select:"Title, Summary, Content, Content_Type, Category, Sub_Category, Department, Custodian, Vendor, Tags, Url, Audience, Modified_Date",
      facets: ["Content_Type,count:100", "Category,count:100", "Department,count:100", "Custodian,count:100"],
      queryType: "full",
      scoringProfile:"default",
      filter: filterQuery
        ? `${filterQuery} and (Audience eq null or Audience eq '' or Audience eq 'Everyone' or Audience eq '${
            currentUserRole !== undefined ? currentUserRole : ""
          }')`
        : `(Audience eq null or Audience eq '' or Audience eq 'Everyone' or Audience eq '${
            currentUserRole !== undefined ? currentUserRole : ""
          }')`,
      count: "true",
      top: pageSize,
      skip: skip,
    };

      // Call your Node API to proxy the request to Azure Cognitive Search
      const response = await axios.post(`${portalApiBaseUrl}/api/search`, { bodyData }, headers);

      // if (response.status === 200) {
      //       sessionStorage.setItem("refreshDialog", "false");
      //     }
      // If the request is successful, return the data
      if (response.status === 200) {
        return response.data;
      }
    }
    catch (error) {
      //throw error;
      // return custom error message from backend if present
      // if (error.response.status) {
      //   sessionStorage.setItem("refreshDialog", "true");
      // }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
