import { LogLevel, InteractionRequiredAuthError } from "@azure/msal-browser";
import { PublicClientApplication } from "@azure/msal-browser";
import { jwtDecode } from "jwt-decode";

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_IDENTITY_CLIENT_ID || "940fa2bc-f4b9-4320-b688-687318fea443",
    authority:
      "https://login.microsoftonline.com/6ab7bf81-0311-401d-87ae-6508f1b32309",
    redirectUri: "/",
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        if (
          level === LogLevel.Info &&
          message.includes("msal:initializeStart")
        ) {
          return;
        }
      },
      logLevel: LogLevel.Info,
    },
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

const refreshDelay = 45 * 60 * 1000;

setInterval(async () => {
  try {
    await getAccessToken(true);
  } catch (error) {
    console.error("Failed to refresh token:", error);
  }
}, refreshDelay);

export const getAccessToken = async (forceRefresh = false) => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length === 0) throw new Error("No account found");

  const silentRequest = {
    scopes: ["openid", "profile", "user.read"],
    account: accounts[0],
    forceRefresh: forceRefresh,
  };

  try {
    const response = await msalInstance.acquireTokenSilent(silentRequest);
    return response.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      console.error("Silent token acquisition failed:", error);

      // Fallback to interactive token acquisition
      try {
        const interactiveResponse = await msalInstance.acquireTokenPopup({
          scopes: ["openid", "profile", "user.read"],
        });
        return interactiveResponse.accessToken;
      } catch (interactiveError) {
        console.error(
          "Interactive token acquisition failed:",
          interactiveError
        );
        throw interactiveError;
      }
    } else {
      throw error;
    }
  }
};

export const getIdToken = async () => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length === 0) throw new Error("No account found");

  const silentRequest = {
    scopes: ["openid", "profile", "user.read"],
    account: accounts[0],
  };

  try {
    const response = await msalInstance.acquireTokenSilent(silentRequest);
    return response.idToken;
  } catch (error) {
    console.error("Silent token acquisition failed:", error);

    // Fallback to interactive token acquisition
    try {
      const interactiveResponse = await msalInstance.acquireTokenPopup({
        scopes: ["openid", "profile", "user.read"],
      });
      return interactiveResponse.idToken;
    } catch (interactiveError) {
      console.error("Interactive token acquisition failed:", interactiveError);
      throw interactiveError;
    }
  }
};

export const checkUserMembershipInGroups = async (userId, groupIdArray) => {
  // Create the batch request body dynamically from the groupIdArray
  const batchRequestBody = {
    requests: groupIdArray.map((group, index) => ({
      id: group.id,
      method: "GET",
      url: `/groups/${group.groupId}/transitiveMembers?$filter=id eq '${userId}'`, // Check user membership in group
    })),
  };
  const accessToken = await getAccessToken();
  const batchUrl = `https://graph.microsoft.com/v1.0/$batch`;
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`, // Correctly formatted Bearer token
      "Content-Type": "application/json",
    },
    body: JSON.stringify(batchRequestBody), // Ensure body is here
  };
  try {
    // Send the batch request using fetch
    const response = await fetch(batchUrl, options); // Check if the response is OK (status code 200-299)
    if (!response.ok) {
      const responseData = await response.json();
      console.error("Error response from batch request:", responseData);
      throw new Error(`Batch request failed with status: ${response.status}`);
    } // Parse the JSON response
    const responseData = await response.json(); // Process the batch response
    const results = responseData.responses;
    const userGroups = {
      isMember: [], // Store group names where the user is a member
      notMember: [], // Store group names where the user is NOT a member
      failed: [], // Store failed checks with error info
    }; // Loop through each response and check status
    results?.forEach((result) => {

      let groupName = groupIdArray?.find(
        (group) => String(group.id) === String(result?.id)
      )?.groupName;

      if (result.status === 200) {
        // If status is 200, user is a member of this group
        userGroups.isMember.push(groupName);
      } else if (result.status === 404) {
        // If status is 404, user is not a member of this group
        userGroups.notMember.push(groupName);
      } else {
        // If status is something else, log it as a failure
        userGroups.failed.push({
          group: groupName,
          error: result.status,
        });
      }
    }); // Output the result
    console.log(
      "User is a member of the following groups:",
      userGroups.isMember
    );
    console.log(
      "User is NOT a member of the following groups:",
      userGroups.notMember
    );
    console.log("Failed group checks:", userGroups.failed);
    return userGroups.isMember; // Return a list of group names where the user is a member
  } catch (error) {
    console.error("Error with batch request:", error);
    throw new Error("Batch request failed");
  }
};

export const loginRequest = {
  scopes: ["user.read"],
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

// Initialize MSAL instance
msalInstance
  .initialize()
  .then(() => {
    console.log("MSAL initialized");
  })
  .catch((error) => {
    console.error("MSAL initialization failed:", error);
  });
