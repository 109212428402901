import React, { useState, useEffect, useRef } from "react";
import { styled, useTheme } from "@mui/material/styles";
import styles from "./styles";
import "./style.scss";
import Box from "@mui/material/Box";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import CustomAccordion from "./../../components/Accordion";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import Header from "./../../components/Header/Index";
import Footer from "./../../components/Footer";
import { useNavigate, useLocation, Link } from "react-router-dom";
import GroupedAutocomplete from "./../../components/Autocomplete";
import Divider from "@mui/material/Divider";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import { getSearch } from "../../redux/actions/Search/search";
import {
  addSelectedValue,
  initializeCheckboxStates,
  removeSelectedValue,
  toggleCheckboxState,
  clearSelectedValues,
} from "../../redux/reducers/Search/searchFilterSlice";
import SearchPagination from "./SearchPagination";
import { getAutoComplete } from "../../redux/actions/Autocomplete/autocomplete";
import { fetchAndStoreToken } from "../../redux/actions/oAuthToken/oAuthToken";
import Loader from "../../components/Loader";
import { setTrainingList } from "../../redux/reducers/TrainingListPath/trainingListPathSlice";
import { getTrainualData } from "../../redux/actions/Trainual/trainual";
import { convertMachineNamesToDisplayNames } from "../../helper/searchFacets";

export default function SearchResult({
  searchDialogState,
  setSearchDialogState,
}) {
  const theme = useTheme();
  const { classes } = styles();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [queryCategory, setQueryCategory] = useState([]);
  const [filterOpen, setFilterOpen] = useState(true);
  const [page, setPage] = useState(0);
  const [srhKey, setSrhKey] = useState("");
  const { search, loading } = useSelector((state) => state.search);
  const [trainingData, setTrainingData] = useState(null);
  const { trainual } = useSelector((state) => state.trainual);
  const [isTrainualFetched, setIsTrainualFetched] = useState(false);
  const fetched = useSelector((state) => state.search.fetched);
  const oAuthToken = localStorage.getItem("oAuthToken");
  const rolesArray = JSON.parse(sessionStorage.getItem("userRoles") || '[]');
  const { autoComplete } = useSelector((state) => state.autoComplete);
  const [options, setOptions] = useState([]);
  const [updateUrl, setUpdateUrl] = useState(false);
  const selectedValues = useSelector(
    (state) => state.searchFilter.selectedValues
  );
  const checkboxStates = useSelector(
    (state) => state.searchFilter.checkboxStates
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (oAuthToken && trainual?.length === 0 && !isTrainualFetched) {
      dispatch(
        getTrainualData({
          oAuthToken: localStorage.getItem("oAuthToken"),
        })
      );
      setIsTrainualFetched(true);
    }
  }, [dispatch, oAuthToken, trainual, isTrainualFetched]);

  useEffect(() => {
    if (trainual) {
      setTrainingData(trainual);
    }
  }, [trainual]);

  useEffect(() => {
    // Initialize checkbox states from Redux state
    dispatch(initializeCheckboxStates({}));
    dispatch(clearSelectedValues());
  }, []);

  const [isTokenReady, setIsTokenReady] = useState(false);
  const { tokenFetched } = useSelector((state) => state.oAuthToken);

  useEffect(() => {
    // If the token is not available in localStorage, fetch and store it
    if (!oAuthToken) {
      dispatch(fetchAndStoreToken()); // Action to fetch and store the token in localStorage
    } else {
      setIsTokenReady(true); // Token is already in localStorage
    }
  }, [oAuthToken, dispatch]); // Dependency array includes `oAuthToken` so that it triggers when the token is updated in localStorage

  useEffect(() => {
    const tokenFromLocalStorage = localStorage.getItem("oAuthToken");

    if (tokenFromLocalStorage) {
      setIsTokenReady(true); // Token is ready, trigger API call
    } else {
      setIsTokenReady(false); // Token is still not available
    }
  }, [tokenFetched]);

  useEffect(() => {
    if (search["@odata.count"] > 0) setOptions([]);
  }, [search.value]);

  useEffect(() => {
    updateQueryString();
  }, [selectedValues, page]);

  useEffect(() => {
    if (rolesArray && srhKey.length > 0) {
      setOptions([]);
      if (
        queryCategory === "" ||
        queryCategory === undefined ||
        queryCategory === null ||
        queryCategory.length === 0
      ) {
        dispatch(
          getSearch({
            searchQuery,
            page: page,
            rolesArray
          })
        );
      } else {
        const queryStringObject = splitQueryCategory(queryCategory);
        const queryString = queryStringFromObject(queryStringObject);
        dispatch(
          getSearch({
            searchQuery,
            filterQuery: queryString,
            page: page,
            oAuthToken: localStorage.getItem("oAuthToken"),
            rolesArray
          })
        );
      }
    }
  }, [srhKey, queryCategory, isTokenReady]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get("srh_key");
    const searchQueryCategory = searchParams.get("category");
    if (query) {
      setSrhKey(query);
      setSearchQuery(query);
      setQueryCategory(searchQueryCategory);
      dispatch(initializeCheckboxStates({}));
      dispatch(clearSelectedValues());
    }
  }, [location.search]);

  useEffect(() => {
    if (autoComplete && autoComplete.value?.length > 0) {
      setOptions(autoComplete.value);
    } else {
      setOptions([]);
    }
  }, [autoComplete.value]);

  const handleTrainingList = (search_result) => {
    const filteredTrainingListItem = trainingData.find(
      (item) => item.subject_title === search_result.Title
    );
    dispatch(
      setTrainingList({
        trainingListItem: filteredTrainingListItem || {},
      })
    );
  };

  const handleFilterOpen = () => {
    setFilterOpen(!filterOpen);
  };

  const handleSearch = () => {
    navigate(`/SearchResult?srh_key=${searchQuery}&category=`);
    setQueryCategory([]);
    setPage(0);
    dispatch(clearSelectedValues());
    dispatch(initializeCheckboxStates({}));
  };

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      if (searchQuery.length > 0) {
        await setOptions([]);
        handleSearch();
      }
    }
  };

  const handleBlur = () => {
    setOptions([]);
  };
  const handleFocus = (event) => {
    if (event.target.value < 3) setOptions([]);
  };

  const handleSearchClick = async () => {
    if (rolesArray && searchQuery.length > 0) {
      await dispatch(
        getSearch({
          searchQuery,
          page: 0,
          rolesArray
        })
      );
      await setOptions([]);
      handleSearch();
    }
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value.length >= 3 && rolesArray) {
      dispatch(
        getAutoComplete({
          searchQuery: e.target.value,
          rolesArray
        })
      );
    } else {
      setOptions([]);
    }
  };

  function handleCheckboxChange(event, filterType, typeObj) {
    setUpdateUrl(true);
    setPage(0);
    const { checked } = event.target;
    const value = typeObj.value.trim() === "" ? "" : typeObj.value;

    // Prepare payload
    const payload = { filterType, value };

    if (checked) {
      // Add the value to the selectedValues object when checkbox is checked
      dispatch(addSelectedValue(payload));
    } else {
      // Remove the value from the selectedValues object when checkbox is unchecked
      dispatch(removeSelectedValue(payload));
    }
    // Toggle checkbox state
    dispatch(toggleCheckboxState(payload));
  }

  function updateQueryString() {
    const newUrlAfterFilter = new URL(window.location);
    let queryStringCategory = [];
    // Check if selectedValues exists and iterate over it
    if (selectedValues) {
      Object.keys(selectedValues).forEach((key) => {
        const valuesArray = selectedValues[key];
        valuesArray.forEach((item) => {
          key === "Content_Type"
            ? queryStringCategory.push(item)
            : queryStringCategory.push(`${key}:${item}`);
        });
      });
    }
    // Construct the query string category parameter
    const queryStringCategoryParam = queryStringCategory.join(",");

    const queryString = queryStringFromObject(selectedValues);

    if (updateUrl)
      newUrlAfterFilter.searchParams.set("category", queryStringCategoryParam);
    window.history.pushState({}, "", newUrlAfterFilter);

    if (rolesArray && searchQuery.length > 0 && queryString.length > 0) {
      setOptions([]);
      dispatch(
        getSearch({
          searchQuery,
          filterQuery: queryString,
          page,
          rolesArray
        })
      );
    } else if (rolesArray && searchQuery.length > 0 && queryCategory.length === 0) {
      dispatch(
        getSearch({
          searchQuery,
          page,
          rolesArray
        })
      );
    } else if (rolesArray && searchQuery.length > 0 && queryCategory.length > 0) {
      const queryStringObject = splitQueryCategory(queryCategory);
      const queryString = queryStringFromObject(queryStringObject);
      dispatch(
        getSearch({
          searchQuery,
          filterQuery: queryString,
          page,
          rolesArray
        })
      );
    }
  }

  function splitQueryCategory(queryCategory) {
    const queryCategoryObject = {};
    const parts = queryCategory.length > 0 ? queryCategory.split(",") : [];

    parts.forEach((part) => {
      if (part.includes(":")) {
        const [key, value] = part.split(":");
        const trimmedKey = key.trim();
        const trimmedValue = value.trim();

        if (!queryCategoryObject[trimmedKey]) {
          queryCategoryObject[trimmedKey] = [];
        }

        queryCategoryObject[trimmedKey].push(trimmedValue);
      } else {
        if (!queryCategoryObject.Content_Type) {
          queryCategoryObject.Content_Type = [];
        }
        queryCategoryObject.Content_Type.push(part.trim());
      }
    });

    return queryCategoryObject;
  }

  function queryStringFromObject(queryCategoryObj) {
    let query = "";
    if (queryCategoryObj) {
      // Handle multiple Content_Type
      if (
        queryCategoryObj.Content_Type &&
        queryCategoryObj.Content_Type.length > 0
      ) {
        if (query.length > 0) query += " and ";
        query += `(${queryCategoryObj.Content_Type.map(
          (value) => `Content_Type eq '${value}'`
        ).join(" or ")})`; // Wrap with parentheses and combine with 'or'
      }

      // Handle multiple Categories
      if (queryCategoryObj.Category && queryCategoryObj.Category.length > 0) {
        if (query.length > 0) query += " and ";
        query += `(${queryCategoryObj.Category.map(
          (value) => `Category eq '${value}'`
        ).join(" or ")})`; // Wrap with parentheses and combine with 'or'
      }

      // Handle multiple Sub_Categories
      if (
        queryCategoryObj.Sub_Category &&
        queryCategoryObj.Sub_Category.length > 0
      ) {
        if (query.length > 0) query += " and ";
        query += `(${queryCategoryObj.Sub_Category.map(
          (value) => `Sub_Category eq '${value}'`
        ).join(" or ")})`; // Wrap with parentheses and combine with 'or'
      }

      // Handle multiple Departments
      if (
        queryCategoryObj.Department &&
        queryCategoryObj.Department.length > 0
      ) {
        if (query.length > 0) query += " and ";
        query += `(${queryCategoryObj.Department.map(
          (value) => `Department eq '${value}'`
        ).join(" or ")})`; // Wrap with parentheses and combine with 'or'
      }

      // Handle multiple Custodians
      if (queryCategoryObj.Custodian && queryCategoryObj.Custodian.length > 0) {
        if (query.length > 0) query += " and ";
        query += `(${queryCategoryObj.Custodian.map(
          (value) => `Custodian eq '${value}'`
        ).join(" or ")})`; // Wrap with parentheses and combine with 'or'
      }
    }
    return query;
  }

  const handleUpdateUrl = (bool) => {
    setUpdateUrl(bool);
  };

  return (
    <>
      <Header
        position={"relative"}
        top="0px"
        setSearchDialogState={setSearchDialogState}
        handleUpdateUrl={handleUpdateUrl}
      />
      <Box
        sx={{
          pt: theme.spacing(2),
          pl: theme.spacing(5),
          pb: theme.spacing(5),
          pr: theme.spacing(5),
        }}
        minHeight="calc(100vh - 170px)"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            paddingTop: "inherit",
          }}
        >
          {/* <FormControl fullWidth sx={{ m: 1 }} variant="standard">
            <InputLabel htmlFor="standard-adornment-amount">Search</InputLabel>
            <Input
              className={classes.searchInput}
              startAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleSearch}><SearchIcon /></IconButton>
                  <IconButton><MicIcon /></IconButton>
                </InputAdornment>}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyPress={handleKeyPress}
            />
          </FormControl> */}
          <Link to="/" className={classes.back}>
            <ArrowBackIcon /> Back{" "}
          </Link>
          {!searchDialogState && (
            <GroupedAutocomplete
              options={options}
              renderGroup={(params) => params.key}
              renderOption={(option) => option.value}
              onChange={(e) => handleInputChange(e)}
              onClick={handleSearchClick}
              onKeyPress={handleKeyPress}
              handleBlur={handleBlur}
              handleFocus={handleFocus}
              value={searchQuery}
            />
          )}
        </Box>
        <Box
          className={classes.result}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <div onClick={handleFilterOpen} style={{ cursor: "pointer" }}>
            <IconButton aria-label="delete">
              <FilterListIcon />
            </IconButton>
            <span className={classes.filterTxt}>Filter</span>
          </div>

          <Box className={classes.resultPanel}>
            <div
              style={{
                display: filterOpen ? "block" : "none",
                [theme.breakpoints.up("md")]: {
                  width: filterOpen ? "25%" : "0%",
                },
                [theme.breakpoints.down("xs")]: {
                  width: filterOpen ? "100%" : "0%",
                },
              }}
            >
              <Collapse orientation="horizontal" in={filterOpen}>
                {fetched &&
                  search["@odata.count"] > 0 &&
                  Object.keys(search)?.length > 0 &&
                  search.value?.length > 0 &&
                  search?.["@search.facets"]?.Content_Type.length > 0 &&
                  !(search?.["@search.facets"]?.Content_Type.length === 1 && !search?.["@search.facets"]?.Content_Type[0].value) &&
                  (
                    <CustomAccordion title="Content Type">
                      {search?.["@search.facets"]?.Content_Type.map(
                        (typeObj) => {
                          return (
                            <div>
                              <FormGroup key={typeObj.value}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        className={classes.checkbox3D}
                                      />
                                    }
                                    label={
                                          typeObj.count
                                          ? `${convertMachineNamesToDisplayNames(
                                            typeObj.value
                                            )} (${typeObj.count})`
                                          : convertMachineNamesToDisplayNames(
                                            typeObj.value
                                            )
                                    }
                                    onChange={(event) => {
                                      const filterType = "Content_Type";
                                      handleCheckboxChange(
                                        event,
                                        filterType,
                                        typeObj
                                      );
                                    }}
                                    checked={
                                      checkboxStates["Content_Type"] &&
                                      checkboxStates["Content_Type"][
                                        typeObj.value
                                      ]
                                    }
                                  />
                              </FormGroup>
                            </div>
                          );
                        }
                      )}
                    </CustomAccordion>
                  )}
                {search &&
                  Object.keys(search)?.length > 0 &&
                  Object.keys(search?.["@search.facets"]).length > 0 &&
                  Object.entries(search?.["@search.facets"]).map(
                    ([key, facetData], index) => {
                      // Exclude "Content_Type" from being listed
                      if (key === "Content_Type") return null;

                      // If the facetData array is not empty, proceed to render
                      if (facetData && facetData.length > 0 && !(facetData.length===1 && !facetData[0].value)) {
                        const title = key; // Title is just the key itself (e.g., 'Category', 'Sub_Category')

                        return (
                          <CustomAccordion title={title} key={index}>
                            <div>
                              {facetData.map((item, itemIndex) => (
                                <FormGroup key={item.value}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          className={classes.checkbox3D}
                                        />
                                      }
                                      label={
                                        item.count
                                          ? `${convertMachineNamesToDisplayNames(
                                              item.value
                                            )} (${item.count})`
                                          : convertMachineNamesToDisplayNames(
                                              item.value
                                            )
                                      }
                                      onChange={(event) => {
                                        handleCheckboxChange(
                                          event,
                                          title,
                                          item
                                        );
                                      }}
                                      checked={
                                        checkboxStates[title] &&
                                        checkboxStates[title][item.value]
                                      }
                                    />
                                </FormGroup>
                              ))}
                            </div>
                          </CustomAccordion>
                        );
                      }

                      return null;
                    }
                  )}
              </Collapse>
            </div>

            {fetched && search["@odata.count"] > 0 && (
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{
                  backgroundColor: theme.palette.common.outline,
                  display: filterOpen ? "block" : "none",
                }}
              />
            )}

            <div
              style={{
                width: filterOpen ? "75%" : "100%",
                // padding: theme.spacing(0),
                // [theme.breakpoints.up('md')]: {
                //   width: filterOpen ? '70%' : '100%',
                // },
                [theme.breakpoints.down("xs")]: {
                  width: filterOpen ? "100%" : "100%",
                },
              }}
            >
              {loading ? (
                <Loader />
              ) : search && fetched ? (
                search?.value?.length > 0 ? (
                  <>
                    <div className={classes.resulttxt}>
                      Found {search["@odata.count"]} results for
                      <div style={{ color: theme.palette.primary.main }}>
                        "{srhKey}"
                      </div>
                    </div>
                    {search.value &&
                      search.value.map((search_result, index) => (
                        <Link
                          key={index}
                          state={search_result}
                          style={{ textDecoration: "none" }}
                          target={
                            search_result.Content_Type === "applications"
                              ? "_blank"
                              : "_self"
                          }
                          rel={
                            search_result.Content_Type === "applications"
                              ? "noopener noreferrer"
                              : ""
                          }
                          to={
                            search_result.Content_Type === "subject"
                              ? "/TrainingList" + search_result.Url
                              : search_result.Url
                          }
                          onClick={() => handleTrainingList(search_result)}
                        >
                          <Box
                            className={classes.resultArticle}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              cursor: "pointer",
                            }}
                          >
                            <div className={classes.resultArticleHeading}>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: search_result.Title,
                                }}
                              ></span>
                              <div className={classes.resultArticleInfo}>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: search_result.Department
                                      ? convertMachineNamesToDisplayNames(
                                          search_result.Department
                                        ) + " "
                                      : "",
                                  }}
                                ></span>{" "}
                                |
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: search_result.Content_Type
                                      ? " " +
                                        convertMachineNamesToDisplayNames(
                                          search_result.Content_Type
                                        ) +
                                        " "
                                      : "",
                                  }}
                                ></span>{" "}
                                |
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: " " + search_result.Modified_Date,
                                  }}
                                ></span>
                              </div>
                              <div
                                className={classes.resultArticleTxt}
                                dangerouslySetInnerHTML={{
                                  __html: search_result.Summary,
                                }}
                              ></div>
                            </div>
                          </Box>
                        </Link>
                      ))}
                  </>
                ) : (
                  <div className={classes.resulttxt}>
                    No result found for
                    <div style={{ color: theme.palette.primary.main }}>
                      "{srhKey}"
                    </div>
                  </div>
                )
              ) : null}
              {search && Object.keys(search)?.length > 0 && search.value && (
                <SearchPagination
                  totalResults={search["@odata.count"]}
                  resultsPerPage={50}
                  page={page}
                  setPage={setPage}
                />
              )}
            </div>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
}
