import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme) => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
    },
    popular: {
      color: theme.palette.info.main,
      fontFamily: "Oswald-Regular",
      fontSize: "14px",
      fontWeight: "700",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    searchInput: {
      flexDirection: "row-reverse",
      padding: theme.spacing(1),
    },
    bannerimgBox: {
      backgroundSize: "cover",
      objectFit: "cover",
      height: "100%",
      width: "100%",
      height: "300px",
    },
    backArrow: {
      display: "flex",
      position: "absolute",
      alignItems: "center",
      top: "105px",
      left: "24px",
    },
    backTxt: {
      fontFamily: "Montserrat-Regular",
      color: theme.palette.common.black,
      fontSize: "16px",
      fontWeight: "600",
      letterSpacing: "1px",
      paddingLeft: "8px",
    },
    BannerItem1: {
      position: "relative",
      paddingLeft: "105px !important",
      paddingTop: "60px !important"
    },
    BannerHeading: {
      paddingLeft: "25px",
      color: theme.palette.common.black,
      fontFamily: "Oswald-Regular",
      fontSize: "46px",
      marginBottom: "0px",
      fontWeight: "500",
      [theme.breakpoints.only('xs')]: {
        width: '230px',
        fontSize: '32px'
      }
    },
    BannerItem2: {
      fontFamily: "Montserrat-Regular",
      fontSize: "16px",
      color: theme.palette.common.black,
      paddingTop: "85px",
      paddingLeft: "40px",
      lineHeight: "18px",
      opacity: "1",
      [theme.breakpoints.only('xs')]: {
        display: 'none',
      }
    },
    chips: {
      borderRadius: theme.spacing(2),
      backgroundColor: theme.palette.primary.light,
      border: `2px solid ${theme.palette.primary.main}`,
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
      padding: theme.spacing(1),
      //marginTop: theme.spacing(2),
    },
    icon: {
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(2),
      borderRadius: theme.spacing(6),
      width: "16px",
      height: "16px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: theme.palette.common.white,
    },
    txt: {
      fontFamily: "Montserrat-Regular",
      color: theme.palette.info.dark,
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "24px",
    },
    filterTxt: {
      color: theme.palette.primary.main,
      fontSize: "14px",
    },
    result: {
      marginTop: theme.spacing(2),
      "& .MuiToolbar-root": {
        paddingLeft: "0px",
        paddingRight: "0px",
      },
      "& .MuiCollapse-wrapperInner": {
        width: "98%",
      },
    },
    resultPanel: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: theme.spacing(2),
      [theme.breakpoints.only("xs")]: {
        flexDirection: "column",
      },
    },
    newResultPanel: {
      minHeight: "52vh",
      padding: "40px 40px 40px 40px",
    },
    resulttxt: {
      fontFamily: "Montserrat-Regular",
      color: theme.palette.info.main,
      fontWeight: "600",
      lineHeight: "24px",
      fontSize: "16px",
      display: "flex",
      gap: "4px",
    },
    resultArticle: {
      marginTop: theme.spacing(2),
    },
    resultArticleHeading: {
      fontFamily: "Oswald-Regular",
      color: theme.palette.secondary.main,
      fontSize: "16px",
      fontWeight: "500",
    },
    resultArticleInfo: {
      fontFamily: "Montserrat-Regular",
      color: theme.palette.info.main,
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "28px",
    },
    resultArticleTxt: {
      fontFamily: "Montserrat-Regular",
      color: theme.palette.info.main,
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "24px",
    },
    back: {
      color: `${theme.palette.info.main} !important`,
      fontFamily: "Montserrat-Regular !important",
      fontSize: "16px !important",
      fontWeight: "600 !important",
      display: "flex",
      gap: "10px",
      alignItems: "center",
      marginBottom: `${theme.spacing(4)} !important`,
      textDecoration: 'none'
    },
    paginationContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: `${theme.spacing(5)}`,
      marginBottom: `${theme.spacing(4)}`,
    },
    paginationText: {
      color: theme.palette.info.main,
      fontFamily: "Montserrat-Regular",
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "16px",
      letterSpacing: "0.25px",
    },
    navigationButton: {
      color: theme.palette.primary.main,
      fontFamily: "Montserrat-Regular",
      fontSize: "16px",
      fontWeight: "600",
      lineHeight: "16px",
      letterSpacing: "0.25px",
      cursor: "pointer",
    },
    checkbox3D: {
      '&.Mui-checked': {
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        transform: 'scale(1.1)',
        transition: 'all 0.3s ease',
      },
      '&:hover': {
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
        transform: 'scale(1.05)',
        transition: 'all 0.3s ease',
      },
      '&:not(.Mui-checked)': {
        // boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
        transform: 'scale(1)',
        transition: 'all 0.3s ease',
      },
      borderRadius:'4px',
      padding:'0px',
      margin: '9px'
    },
  }
}
);

export default styles;
