import React, { useEffect, useState, useRef } from "react";
import ReactMarkdown from "react-markdown";
import { fetchStewBotChat } from "../../helper/stewBot";
import Loader from "../Loader";
import "./style.scss";
import { Link } from "react-router-dom";

const StewBot = (props) => {
    const clearMessage = props?.clearMessage;
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState("");
    const [isStreaming, setIsStreaming] = useState(false);
    const scrollRef = useRef(null);
    // const chatBotHeight = document?.documentElement?.clientHeight - 225;
    // const [stewBotHeight, setStewBotHeight] = useState(document?.documentElement?.clientHeight - 225);

    const styles = {
        container: {
            fontFamily: "'Montserrat', sans-serif",
            width: "100%",
            maxWidth: "600px",
            margin: "0 auto",
            border: "1px solid #ccc",
            borderRadius: "0px 0px 10px 10px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            height: "75vh",
            backgroundColor: "#FFFFFF",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        },
        header: {
            textAlign: "center",
            backgroundColor: "#2A7CB5",
            color: "#FFFFFF",
            padding: "10px 0",
            margin: 0,
            fontSize: "24px",
            letterSpacing: "2px"
        },
        chatContainer: {
            flex: 1,
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            padding: "10px",
            gap: "10px",
        },
        message: {
            maxWidth: "70%",
            padding: "10px 15px",
            fontSize: "14px",
            wordWrap: "break-word",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        },
        inputContainer: {
            display: "flex",
            alignItems: "center",
            padding: "10px",
            borderTop: "1px solid #ccc",
            backgroundColor: "#F8F9FA",
        },
        input: {
            flex: 1,
            padding: "10px",
            fontSize: "14px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            outline: "none",
            marginRight: "10px",
            fontFamily: "'Montserrat', sans-serif",
        },
        button: {
            padding: "10px 20px",
            fontSize: "14px",
            color: "#FFFFFF",
            backgroundColor: "#2A7CB5",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            outline: "none",
            fontFamily: "Montserrat-Regular",
        },
    };

    // Scroll to the bottom of the chat container whenever messages change
    useEffect(() => {
        if (scrollRef && scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: "smooth" });
        }
        window.scrollTo(0, 0);
    }, [messages, scrollRef?.current]);

    useEffect(() => {
        if (clearMessage) {
            setMessages([]);
            props?.handleClearMessageState(false);
        }
    }, [clearMessage]);

    // useEffect(() => {
    //     // Adjust chatBot on window resize
    //     const handleResize = () => setStewBotHeight(document?.documentElement?.clientHeight - 225);
    //     window.addEventListener('resize', handleResize);
    //     return () => window.removeEventListener('resize', handleResize);
    // }, []);

    const handleSendMessage = async () => {
        if (!input.trim()) {
            alert("Input cannot be empty");
            return;
        }

        const userMessage = { role: "user", content: input };
        setMessages((prevMessages) => [...prevMessages, userMessage]);

        try {
            setIsStreaming(true);

            // Get the full JSON response
            const botResponse = await fetchStewBotChat(input);

            // Extract the content and citations
            const message = botResponse.choices[0].message;
            const content = message.content; // The bot's response
            const citations = message.context?.citations || []; // Citations, if any

            // Update the messages with the assistant's response
            setMessages((prevMessages) => [
                ...prevMessages,
                {
                    role: "assistant",
                    content,
                    context: { citations }, // Pass citations for rendering
                },
            ]);
        } catch (error) {
            console.error("Error:", error);
            setMessages((prevMessages) => [
                ...prevMessages,
                { role: "assistant", content: "Something went wrong. Please try again." },
            ]);
        } finally {
            setIsStreaming(false);
            setInput(""); // Clear input
        }
    };

    // const getUniqueCitations = (citations) => {
    //     const uniqueFilepaths = new Set();
    //     return citations.filter((citation) => {
    //         if (uniqueFilepaths.has(citation.filepath)) {
    //             return false; // Skip duplicates
    //         }
    //         uniqueFilepaths.add(citation.filepath);
    //         return true; // Keep unique filepath
    //     });
    // };

    const replacePlaceholdersWithLinks = (text, citations) => {
        // Filter unique citations
        //const uniqueCitations = getUniqueCitations(citations);
        return text.replace(/(doc\d+)/g, (match) => {
            // Extract the index from the match (e.g., "doc1" -> 1)
            const index = parseInt(match.replace("doc", ""), 10) - 1;
            const citation = citations[index];

            if (citation && citation.filepath) {
                // Replace with Markdown hyperlink
                return `[${match}](<${citation.filepath}>)`;
            }
            return match; // Fallback to the original text if no citation is found
        });
    };

    const renderMessageContent = (content, citations = []) => {
        const processedContent = replacePlaceholdersWithLinks(content, citations);

        return <ReactMarkdown className='stewBotCustomStyle'
            components={{
                a: ({ node, href, ...props }) => {
                    const isAbsoluteUrl = /^https?:\/\//.test(href); // Check if URL starts with http:// or https://
                    return isAbsoluteUrl ? (
                        <a
                            {...props}
                            href={href}
                            target={"_blank"}
                            rel={"noopener noreferrer"}
                        >
                            {props.children}
                        </a>
                    ) : (
                        <Link
                            {...props}
                            to={href}
                            style={{ color: "#2A7CB5", textDecoration: "underline" }}
                        >
                            {props.children}
                        </Link>
                    );
                },
            }}
        >{processedContent}</ReactMarkdown>;
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            handleSendMessage();
        }
    };

    return (
        <>
            {/* <Header />
            <Box sx={{ padding: '120px 0px 50px' }}> */}
            <div style={styles.container}>
                {/* <h1 style={styles.header}>Stewbot</h1> */}
                <div style={styles.chatContainer}>
                    {messages.map((msg, index) => (
                        <>
                            {/* Dummy div to ensure scrolling */}
                            <div ref={scrollRef} />
                            <div
                                key={index}
                                style={{
                                    ...styles.message,
                                    alignSelf: msg.role === "user" ? "flex-end" : "flex-start",
                                    backgroundColor: msg.role === "user" ? "#D1E7DD" : "rgb(205, 235, 255)",
                                    borderRadius: msg.role === "user" ? "20px 20px 0 20px" : "20px 20px 20px 0",
                                }}
                            >
                                <strong>{msg.role === "user" ? "You" : "Stewbot"}:</strong>
                                {renderMessageContent(msg.content, msg.context?.citations || [])}
                            </div>
                        </>
                    ))}
                    {isStreaming && (<Loader />)}
                </div>
                <div style={styles.inputContainer}>
                    <input
                        type="text"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyDown={(e) => handleKeyPress(e)}
                        placeholder="Type your message..."
                        style={styles.input}
                    />
                    <button onClick={handleSendMessage} style={styles.button} disabled={isStreaming}>
                        {isStreaming ? "Loading..." : "Send"}
                    </button>
                </div>
            </div>
            {/* </Box > */}
            {/* <Footer /> */}
        </>
    );
};

export default StewBot;
