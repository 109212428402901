import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme) => {
    return {
        filterBox: { paddingTop: "100px", paddingLeft: "40px" },
        widgetContent: {
            minHeight: "600px",
            padding: `${theme.spacing(16)} ${theme.spacing(5)}  ${theme.spacing(4)} ${theme.spacing(4)}`,
            [theme.breakpoints.down('md')]: {
                padding: `${theme.spacing(13)} ${theme.spacing(3)}  ${theme.spacing(3)} ${theme.spacing(3)} !important`,
                // marginTop: theme.spacing(4),
            },
            marginBottom: "32px"
        },
        staticWidgetContent: {
            padding: `${theme.spacing(16)} ${theme.spacing(5)}  ${theme.spacing(4)} ${theme.spacing(4)} !important`,
            [theme.breakpoints.down('md')]: {
                padding: `${theme.spacing(13)} ${theme.spacing(3)}  ${theme.spacing(3)} ${theme.spacing(3)} !important`,
                // marginTop: theme.spacing(4),
            }
        },
        noWidgetContent: {
            minHeight: "500px",
            justifyItems: "center",
            padding: `${theme.spacing(25)} ${theme.spacing(5)}  ${theme.spacing(4)} ${theme.spacing(5)}`,
            [theme.breakpoints.down('md')]: {
                padding: theme.spacing(3),
            }
        },
        importantUpdatesHeader: {
            display: "inline-flex",
            alignItems: "center",
            gap: theme.spacing(2),
            width: "100%",
            padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} !important`,
        },
        companyNewsHeader: {
            display: "inline-flex",
            alignItems: "center",
            gap: theme.spacing(2),
            width: "100%",
            padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} !important`,
        },
        icon: {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.main,
            borderRadius: theme.spacing(5),
            width: "48px",
            height: "48px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        importantUpdatesHeaderText: {
            color: theme.palette.secondary.main,
            fontFamily: "Oswald-Regular",
            fontSize: "32px",
            fontWeight: "600",
            lineHeight: "38px",
        },
        companyNewsHeaderText: {
            color: theme.palette.secondary.main,
            fontFamily: "Oswald-Regular",
            fontSize: "32px",
            fontWeight: "600",
            lineHeight: "38px",
        },
        favContentItem1: {
            display: "flex",
            justifyContent: "space-around",
            paddingTop: '0px !important',
            [theme.breakpoints.down("md")]: { paddingLeft: '48px !important', },
            maxWidth: "10% !important",
            [theme.breakpoints.down("sm")]: {
                //bottom: '0px !important',
                //paddingLeft: '0px !important',
                //paddingTop: '0px !important',
                //padding: '0px!important',
                //right: '70px !important'
            },
            padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} !important`,
        },
    }
},
);


export default styles;