import { createSlice } from '@reduxjs/toolkit'
import { getTrainingDetailData } from '../../actions/TrainingDetail/trainingDetail';

const initialState = {
    loading: true,
    error: false,
    trainingDetail: [],
    message: null,
};
const trainingDetailSlice = createSlice({
    name: 'trainingDetail',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //Get Notice
        builder.addCase(getTrainingDetailData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getTrainingDetailData.fulfilled, (state, action) => {
            state.loading = false;
            state.trainingDetail = action.payload;
            state.error = "";
        });
        builder.addCase(getTrainingDetailData.rejected, (state, action) => {
            state.loading = false;
            state.trainingDetail = [];
            state.error = action.error.message;
        });
    }
})

export default trainingDetailSlice.reducer
