import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import styles from "./styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Grid, IconButton } from "@mui/material";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FavoriteApps from "../../views/FavoriteApps";
import FullScreenDialog from "../Dialog";
import FavoritePage from "../../views/FavoriteApps/FavoritePage";

export default function SimpleSlider(props) {
  const theme = useTheme();
  const { classes } = styles();
  const dispatch = useDispatch();
  const  oAuthToken  = localStorage.getItem("oAuthToken");
  const [openFavDialog, setOpenFavDialog] = useState(false);
  const [favorites, setFavorites] = useState(false);
  const userId = useSelector((state) => state.userData.userData.id);
  const favoritesApps = useSelector((state) => state.getFavorites);
  const favoritesContents = useSelector((state) => state.getFavoritesContents);
  const fetched = useSelector((state) => state.getFavorites.fetched);
  const isMobile = useMediaQuery("(max-width:768px)");

  const handleCloseFavDialog = () => {
    setOpenFavDialog(false);
  };

  const [tabPanelState, setTabPanelState] = useState(null);

  const handleTabPanelState = (tabState) => {
    setTabPanelState(tabState);
  };

  return (
    <div>
      <Box
        sx={{
          backgroundColor: theme.palette.common.white,
          backgroundSize: "cover",
          minHeight: "280px",
          color: theme.palette.secondary.main,
          // margin: isMobile ? `${theme.spacing(0)} ${theme.spacing(3)} ${theme.spacing(0)} ${theme.spacing(3)}` : `${theme.spacing(0)} ${theme.spacing(5)} ${theme.spacing(0)} ${theme.spacing(5)}`,
          // border: `1px solid ${theme.palette.common.outline}`,
          borderRadius: "8px",
          // overflowY: "scroll",
        }}
      >
        <Grid
          container
          spacing={4}
          className={
            isMobile
              ? classes.favMobileDashboardcontent
              : classes.favDashboardcontent
          }
        >
          {/* <Grid className={classes.favContentItem1} item sm={12} md={3}>
            <div
              style={{
                display: "flex",
                fontFamily: "Oswald-Regular",
                fontSize: "32px",
                fontWeight: "600",
                color: theme.palette.secondary.main,
                paddingLeft: isMobile ? "0px" : "16px",
              }}
            >
              <FavoriteBorderOutlinedIcon
                sx={{
                  fontSize: "1.5rem",
                  borderRadius: "40px",
                  padding: "8px",
                  marginRight: isMobile ? "8px" : "16px",
                  backgroundColor: theme.palette.favorite.light,
                  color: theme.palette.primary.main,
                  width: "32px",
                  height: "32px"
                }}
              />
              Favorites
            </div>
            <Link
              to={"/Favorites"}
              style={{
                position: "absolute",
                right: "0px",
                textDecoration: "none",
                zIndex: "999",
              }}
              state={tabPanelState}
            >
              <IconButton
                sx={{
                  display: "flex",
                  fontFamily: "Montserrat-Regular",
                  fontSize: "18px",
                  color: theme.palette.primary.main,
                  "&:hover": { backgroundColor: "transparent" },
                  margin: isMobile ? "8px" : "0"
                }}
              >
                <ArrowCircleRightOutlinedIcon
                  sx={{
                    marginRight: isMobile ? "2px" : "16px",
                    color: theme.palette.primary.main,
                  }}
                />
                View More
              </IconButton>
            </Link>
          </Grid> */}
          <Grid className={classes.favContentItem2} item sm={12} md={9}>
            <FavoritePage
              userId={userId}
              favoritesApps={favoritesApps}
              favoritesContents={favoritesContents}
              isDashboard={true}
              fetched={fetched}
              handleTabPanelState={handleTabPanelState}
            />
          </Grid>
        </Grid>
      </Box>
      {/* <FullScreenDialog
        open={openFavDialog}
        onClose={handleCloseFavDialog}
        title={"Favorites"}
        content={<FavoritePage userId={userId} favoritesApps={favoritesApps} favoritesContents={favoritesContents} />}
        favorites={favorites}
      /> */}
    </div>
  );
}
