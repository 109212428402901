import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import styles from "../SearchResult/styles";
import { Grid, Box } from "@mui/material";
import "../SearchResult/style.scss";
import slider2 from "../../assets/images/slider/slider2.png";
import Header from "./../../components/Header/Index";
import Footer from "./../../components/Footer";
import { useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSearch } from "../../redux/actions/Search/search";
import SearchPagination from "../SearchResult/SearchPagination";
import { fetchAndStoreToken } from "../../redux/actions/oAuthToken/oAuthToken";
import Loader from "../../components/Loader";
import { convertMachineNamesToDisplayNames } from "../../helper/searchFacets";

export default function NewSearchResult() {
  const theme = useTheme();
  const { classes } = styles();
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState("");
  const [queryCategory, setQueryCategory] = useState("");
  const [page, setPage] = useState(0);
  const [srhKey, setSrhKey] = useState("");
  const [linkTitle, setLinkTitle] = useState(null);
  const { search, loading } = useSelector((state) => state.search);
  const fetched = useSelector((state) => state.search.fetched);
  const oAuthToken = localStorage.getItem("oAuthToken");
  const rolesArray = JSON.parse(sessionStorage.getItem("userRoles") || '[]');
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();
  const [isTokenReady, setIsTokenReady] = useState(false);
  const { tokenFetched } = useSelector((state) => state.oAuthToken);

  useEffect(() => {
    // If the token is not available in localStorage, fetch and store it
    if (!oAuthToken) {
      dispatch(fetchAndStoreToken()); // Action to fetch and store the token in localStorage
    } else {
      setIsTokenReady(true); // Token is already in localStorage
    }
  }, [oAuthToken, dispatch]); // Dependency array includes `oAuthToken` so that it triggers when the token is updated in localStorage

  useEffect(() => {
    const tokenFromLocalStorage = localStorage.getItem("oAuthToken");

    if (tokenFromLocalStorage) {
      setIsTokenReady(true); // Token is ready, trigger API call
    } else {
      setIsTokenReady(false); // Token is still not available
    }
  }, [tokenFetched]);

  useEffect(() => {
    if (search["@odata.count"] > 0) setOptions([]);
  }, [search.value]);

  useEffect(() => {
    updateQueryString(queryCategory);
  }, [queryCategory, page]);

  useEffect(() => {
    if (rolesArray && srhKey.length > 0) {
      setOptions([]);
      if (
        queryCategory === "" ||
        queryCategory === undefined ||
        queryCategory === null ||
        queryCategory.length === 0
      ) {
        dispatch(
          getSearch({
            searchQuery,
            page: page,
            rolesArray
          })
        );
      } else {
        let queryString = updateQueryString(queryCategory);
        dispatch(
          getSearch({
            searchQuery,
            filterQuery: queryString,
            page: page,
            rolesArray
          })
        );
      }
    }
  }, [srhKey, queryCategory, isTokenReady, page]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const title = searchParams.get("title");
    const query = searchParams.get("srh_key");
    const searchQueryCategory = searchParams.get("category");
    if (query) {
      setLinkTitle(title);
      setSrhKey(query);
      setSearchQuery(query);
      setQueryCategory(searchQueryCategory);
    }
  }, [location.search]);

  function updateQueryString(queryCategory) {
    const queryStringObject = splitQueryCategory(queryCategory);
    const queryString = queryStringFromObject(queryStringObject);

    return queryString;
  }

  function splitQueryCategory(queryCategory) {
    const queryCategoryObject = {};
    const parts = queryCategory?.length > 0 ? queryCategory.split(",") : [];

    parts.forEach((part) => {
      if (part.includes(":")) {
        const [key, value] = part.split(":");
        const trimmedKey = key.trim();
        const trimmedValue = value.trim();

        if (!queryCategoryObject[trimmedKey]) {
          queryCategoryObject[trimmedKey] = [];
        }

        queryCategoryObject[trimmedKey].push(trimmedValue);
      } else {
        if (!queryCategoryObject.Content_Type) {
          queryCategoryObject.Content_Type = [];
        }
        queryCategoryObject.Content_Type.push(part.trim());
      }
    });

    return queryCategoryObject;
  }

  function queryStringFromObject(queryCategoryObj) {
    let query = "";
    if (queryCategoryObj) {
      // Handle multiple Content_Type
      if (
        queryCategoryObj.Content_Type &&
        queryCategoryObj.Content_Type.length > 0
      ) {
        if (query.length > 0) query += " and ";
        query += `(${queryCategoryObj.Content_Type.map(
          (value) => `Content_Type eq '${value}'`
        ).join(" or ")})`; // Wrap with parentheses and combine with 'or'
      }

      // Handle multiple Categories
      if (queryCategoryObj.Category && queryCategoryObj.Category.length > 0) {
        if (query.length > 0) query += " and ";
        query += `(${queryCategoryObj.Category.map(
          (value) => `Category eq '${value}'`
        ).join(" or ")})`; // Wrap with parentheses and combine with 'or'
      }

      // Handle multiple Sub_Categories
      if (
        queryCategoryObj.Sub_Category &&
        queryCategoryObj.Sub_Category.length > 0
      ) {
        if (query.length > 0) query += " and ";
        query += `(${queryCategoryObj.Sub_Category.map(
          (value) => `Sub_Category eq '${value}'`
        ).join(" or ")})`; // Wrap with parentheses and combine with 'or'
      }

      // Handle multiple Departments
      if (
        queryCategoryObj.Department &&
        queryCategoryObj.Department.length > 0
      ) {
        if (query.length > 0) query += " and ";
        query += `(${queryCategoryObj.Department.map(
          (value) => `Department eq '${value}'`
        ).join(" or ")})`; // Wrap with parentheses and combine with 'or'
      }

      // Handle multiple Custodians
      if (queryCategoryObj.Custodian && queryCategoryObj.Custodian.length > 0) {
        if (query.length > 0) query += " and ";
        query += `(${queryCategoryObj.Custodian.map(
          (value) => `Custodian eq '${value}'`
        ).join(" or ")})`; // Wrap with parentheses and combine with 'or'
      }
    }
    return query;
  }

  const style = {
    content: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#CDEBFF",
      opacity: "0.85",
    },
  };

  return (
    <>
      <Header />
      <Box className={classes.bannerimgBox}>
        <Grid container spacing={4} style={style.content}>
          <Link style={{ zIndex: 1 }} to={-2}>
            <div className={classes.backArrow}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M20 11.1562H7.83L13.42 5.56625L12 4.15625L4 12.1562L12 20.1562L13.41 18.7463L7.83 13.1562H20V11.1562Z"
                  fill="#000000"
                />
              </svg>
              <div className={classes.backTxt}>Back</div>
            </div>
          </Link>
          <Grid item xs={6} xsOffset={1} className={classes.BannerItem1}>
            <h1 className={classes.BannerHeading}>
              {linkTitle ? linkTitle : ""}
            </h1>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.BannerItem2}>{""}</div>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.newResultPanel}>
        <div
          style={{
            width: "100%",
          }}
        >
          {loading ? (
            <Loader />
          ) : search && fetched ? (
            search["@odata.count"] > 0 ? (
              <>
                {search.value &&
                  search.value.map((search_result, index) => (
                    <Link
                      key={index}
                      to={
                        search_result.Content_Type === "subject"
                          ? "/TrainingList" + search_result.Url
                          : search_result.Url
                      }
                      state={search_result}
                      style={{ textDecoration: "none" }}
                    >
                      <Box
                        className={classes.resultArticle}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          cursor: "pointer",
                        }}
                      >
                        <div className={classes.resultArticleHeading}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: search_result.Title,
                            }}
                          ></span>
                          <div className={classes.resultArticleInfo}>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: search_result.Department
                                  ? convertMachineNamesToDisplayNames(
                                      search_result.Department
                                    ) + " "
                                  : "",
                              }}
                            ></span>{" "}
                            |
                            <span
                              dangerouslySetInnerHTML={{
                                __html: search_result.Content_Type
                                  ? " " +
                                    convertMachineNamesToDisplayNames(
                                      search_result.Content_Type
                                    ) +
                                    " "
                                  : "",
                              }}
                            ></span>{" "}
                            |
                            <span
                              dangerouslySetInnerHTML={{
                                __html: " " + search_result.Modified_Date,
                              }}
                            ></span>
                          </div>
                          <div
                            className={classes.resultArticleTxt}
                            dangerouslySetInnerHTML={{
                              __html: search_result.Summary,
                            }}
                          ></div>
                        </div>
                      </Box>
                    </Link>
                  ))}
              </>
            ) : (
              <div className={classes.resulttxt}>
                No result found for
                <div style={{ color: theme.palette.primary.main }}>
                  "{srhKey}"
                </div>
              </div>
            )
          ) : null}
          {search && Object.keys(search)?.length > 0 && search.value && (
            <SearchPagination
              totalResults={search["@odata.count"]}
              resultsPerPage={50}
              page={page}
              setPage={setPage}
            />
          )}
        </div>
      </Box>
      <Footer />
    </>
  );
}
