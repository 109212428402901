import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme) => {
    return {
        chatIcon: {
            position: "fixed",
            bottom: 60,
            right: 5,
            height: 60,
            width: 60,
            backgroundColor: "#2A7CB5",
            color: "#FFFFFF",
            "&:hover": { backgroundColor: "#2A7CB5" },
            zIndex: 1
        },
        chatBoxHeader: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "14px",
            fontFamily: 'Oswald-Regular',
            borderRadius: "10px 10px 0px 0px",
            backgroundColor: "#2A7CB5",
            color: "white"
        }
    }
}
);

export default styles;