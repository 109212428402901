import React, { useEffect, useRef } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import { styled, lighten, darken } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { convertMachineNamesToDisplayNames } from "../../helper/searchFacets";
import { setTrainingList } from "../../redux/reducers/TrainingListPath/trainingListPathSlice";

const GroupedAutocomplete = ({
  options,
  onChange,
  onKeyPress,
  onClick,
  handleBlur,
  handleFocus,
  value,
  setOpenDialog,
}) => {
  const GroupHeader = styled("div")(({ theme }) => ({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: theme.palette.secondary.main,
    backgroundColor:
      theme.palette.mode === "light"
        ? lighten(theme.palette.primary.main, 0.85)
        : darken(theme.palette.secondary.main, 0.8),
  }));

  // Create a styled SearchIcon with a 3D effect
const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '4px',
  borderRadius: '4px',
  marginBottom: '8px',
  backgroundColor: theme.palette.common.white,
  // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  transform: 'scale(1)',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.common.white,
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
    transform: 'scale(1.1)',
  },
  '&:active': {
    backgroundColor: theme.palette.common.white,
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.4)',
    transform: 'scale(1.05)',
  },
}));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme=useTheme()
  const GroupItems = styled("ul")({
    padding: 0,
  });

  const inputRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [insideDropdown, setInsideDropdown] = useState(false);
  const [trainingData, setTrainingData] = useState(null);
  const { trainual } = useSelector((state) => state.trainual);

  useEffect(() => {
    if (trainual) {
      setTrainingData(trainual);
    }
  }, [trainual]);

  useEffect(() => {
    // Focus on the input field when the component mounts
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const selectedOptionRef = useRef(null); // Using useRef to hold the currently selected option

  const handleMouseEnter = () => {
    setInsideDropdown(true);
  };

  const handleMouseLeave = () => {
    setInsideDropdown(false);
  };
  const handleKeyDown = (event) => {
    // Check if Enter is pressed and there is a selected option in the ref
    if (event.key === "Enter" && selectedOptionRef.current && insideDropdown) {
      handleSelectedOption(selectedOptionRef.current);
    }
  };

  const handleTrainingList = (search_result) => {
    const filteredTrainingListItem = trainingData && trainingData.find(
      (item) => item.subject_title === search_result.Title
    );
    dispatch(
      setTrainingList({
        trainingListItem: filteredTrainingListItem || {}
      })
    );
  };

  const handleSelectedOption = (option) => {
    if (insideDropdown) {
      setOpenDialog && setOpenDialog(false);
      if (option.Content_Type === "applications") {
        window.open(option.Url, '_blank', 'noopener,noreferrer');
      } 
      else if(option.Content_Type === "subject"){
        navigate("/TrainingList"+option.Url);  
      }
      else {
        navigate(option.Url);
        handleTrainingList(option);
      }
    }
    else {
      onClick && onClick();
    }
  };

  return (
    <Autocomplete
      id="grouped-Search"
      open={value.length >= 3 && options.length > 0}
      popupIcon={<StyledSearchIcon onClick={onClick} />}
      filterOptions={(x) => x}
      onBlur={handleBlur}
      onFocus={handleFocus}
      options={options} // Use options directly
      getOptionLabel={() => searchTerm}
      onHighlightChange={(_, option) => {
        if (option) selectedOptionRef.current = option; // Update ref when an option is highlighted
      }}
      onChange={(_, newValue) => {
        if (newValue) handleSelectedOption(newValue);
      }}
      renderInput={(params, option) => (
        <TextField
          variant="standard"
          {...params}
          label="Search"
          InputLabelProps={{ style: { fontSize: "16px" } }} // Change the font size here
          onChange={(e) => {
            setSearchTerm(e.target.value);
            onChange && onChange(e);
          }}
          onKeyPress={onKeyPress}
          onKeyDown={(event) => handleKeyDown(event)}
          inputRef={inputRef}
        />
      )}
      renderOption={(props, option) => (
        <li
          {...props}
          style={{ textDecoration: "none", color: "inherit" }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleSelectedOption(option)}
          role="option"
          tabIndex={0}
          dangerouslySetInnerHTML={{ __html:"<strong style='color: #0C3752;'>" + convertMachineNamesToDisplayNames(option.Content_Type) + "</strong>"+"&nbsp;"+"-"+"&nbsp;"+option.Title }}
        ></li>
      )}
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
      sx={{
        width: "100%",
        "& .MuiAutocomplete-popupIndicator": { transform: "none" },
      }}
    />
  );
};

export default GroupedAutocomplete;
